.pf-c-form {
  --pf-c-form--GridGap: var(--pf-global--gutter--md);
  --pf-c-form__group--m-action--MarginTop: var(--pf-global--spacer--xl);
  --pf-c-form--m-horizontal__group-label--md--GridColumnWidth: 9.375rem;
  --pf-c-form--m-horizontal__group-label--md--GridColumnGap: var(--pf-global--spacer--md);
  --pf-c-form--m-horizontal__group-control--md--GridColumnWidth: 1fr;
  --pf-c-form--m-limit-width--MaxWidth: 31.25rem;
  --pf-c-form--m-horizontal__group-label--md--PaddingTop: var(--pf-global--spacer--sm);
  --pf-c-form__group-label--PaddingBottom: var(--pf-global--spacer--sm);
  --pf-c-form__label--FontSize: var(--pf-global--FontSize--sm);
  --pf-c-form__label--LineHeight: var(--pf-global--LineHeight--sm);
  --pf-c-form__label--m-disabled--Color: var(--pf-global--disabled-color--100);
  --pf-c-form__label-text--FontWeight: var(--pf-global--FontWeight--bold);
  --pf-c-form__label-required--MarginLeft: var(--pf-global--spacer--xs);
  --pf-c-form__label-required--FontSize: var(--pf-global--FontSize--sm);
  --pf-c-form__label-required--Color: var(--pf-global--danger-color--100);
  --pf-c-form__group-label-help--PaddingTop: var(--pf-global--spacer--xs);
  --pf-c-form__group-label-help--PaddingRight: var(--pf-global--spacer--xs);
  --pf-c-form__group-label-help--PaddingBottom: var(--pf-global--spacer--xs);
  --pf-c-form__group-label-help--PaddingLeft: var(--pf-global--spacer--xs);
  --pf-c-form__group-label-help--MarginTop: calc(var(--pf-c-form__group-label-help--PaddingTop) * -1);
  --pf-c-form__group-label-help--MarginRight: calc(var(--pf-c-form__group-label-help--PaddingRight) * -1);
  --pf-c-form__group-label-help--MarginBottom: calc(var(--pf-c-form__group-label-help--PaddingBottom) * -1);
  --pf-c-form__group-label-help--MarginLeft: calc(var(--pf-c-form__group-label-help--PaddingLeft) * -1 + var(--pf-global--spacer--xs));
  --pf-c-form__group-label-help--FontSize: var(--pf-global--FontSize--sm);
  --pf-c-form__group-label-help--TranslateY: 0.125rem;
  --pf-c-form__group-label-help--Color: var(--pf-global--Color--200);
  --pf-c-form__group-label-help--hover--Color: var(--pf-global--Color--100);
  --pf-c-form__group-label-help--focus--Color: var(--pf-global--Color--100);
  --pf-c-form__group-label-info--MarginLeft: var(--pf-global--spacer--sm);
  --pf-c-form__group-label-info--FontSize: var(--pf-global--FontSize--sm);
  --pf-c-form__group-control--m-inline--child--MarginRight: var(--pf-global--spacer--lg);
  --pf-c-form__group-control__helper-text--MarginBottom: var(--pf-global--spacer--xs);
  --pf-c-grid__group-control--m-stack--Gap: var(--pf-global--spacer--sm);
  --pf-c-grid__group-control--m-stack__helper-text--MarginTop: calc(var(--pf-c-grid__group-control--m-stack--Gap) * -1 + var(--pf-c-form__helper-text--MarginTop--base));
  --pf-c-form__actions--child--MarginTop: var(--pf-global--spacer--sm);
  --pf-c-form__actions--child--MarginRight: var(--pf-global--spacer--sm);
  --pf-c-form__actions--child--MarginBottom: var(--pf-global--spacer--sm);
  --pf-c-form__actions--child--MarginLeft: var(--pf-global--spacer--sm);
  --pf-c-form__actions--MarginTop: calc(var(--pf-c-form__actions--child--MarginTop) * -1);
  --pf-c-form__actions--MarginRight: calc(var(--pf-c-form__actions--child--MarginRight) * -1);
  --pf-c-form__actions--MarginBottom: calc(var(--pf-c-form__actions--child--MarginBottom) * -1);
  --pf-c-form__actions--MarginLeft: calc(var(--pf-c-form__actions--child--MarginLeft) * -1);
  --pf-c-form__helper-text--MarginTop--base: var(--pf-global--spacer--xs);
  --pf-c-form__helper-text--MarginTop: var(--pf-c-form__helper-text--MarginTop--base);
  --pf-c-form__helper-text--FontSize: var(--pf-global--FontSize--sm);
  --pf-c-form__helper-text--Color: var(--pf-global--Color--100);
  --pf-c-form__helper-text-icon--FontSize: var(--pf-global--FontSize--md);
  --pf-c-form__helper-text-icon--MarginRight: var(--pf-global--spacer--xs);
  --pf-c-form__helper-text--m-success--Color: var(--pf-global--success-color--200);
  --pf-c-form__helper-text--m-warning--Color: var(--pf-global--warning-color--200);
  --pf-c-form__helper-text--m-error--Color: var(--pf-global--danger-color--100);
  --pf-c-form__section--MarginTop: var(--pf-global--spacer--xl);
  --pf-c-form__section--Gap: var(--pf-global--gutter--md);
  --pf-c-form__section-title--FontSize: var(--pf-global--FontSize--lg);
  --pf-c-form__section-title--FontWeight: var(--pf-global--FontWeight--bold);
  --pf-c-form__section-title--MarginBottom: calc(var(--pf-global--spacer--sm) * -1);
  --pf-c-form__field-group--border-width-base: var(--pf-global--BorderWidth--sm);
  --pf-c-form__field-group--BorderTopWidth: var(--pf-c-form__field-group--border-width-base);
  --pf-c-form__field-group--BorderTopColor: var(--pf-global--BorderColor--100);
  --pf-c-form__field-group--BorderBottomWidth: var(--pf-c-form__field-group--border-width-base);
  --pf-c-form__field-group--BorderBottomColor: var(--pf-global--BorderColor--100);
  --pf-c-form__field-group--field-group--MarginTop: calc(var(--pf-c-form--GridGap) * -1);
  --pf-c-form__field-group--GridTemplateColumns--toggle: calc(var(--pf-global--spacer--md) * 2 + var(--pf-c-form__field-group-toggle-icon--MinWidth) + var(--pf-global--spacer--xs));
  --pf-c-form__field-group-toggle--PaddingTop: var(--pf-global--spacer--md);
  --pf-c-form__field-group-toggle--PaddingRight: var(--pf-global--spacer--xs);
  --pf-c-form__field-group__field-group__field-group-toggle--PaddingTop: var(--pf-global--spacer--lg);
  --pf-c-form__field-group-header-toggle--BorderWidth--base: var(--pf-global--BorderWidth--sm);
  --pf-c-form__field-group__field-group--field-group__field-group-toggle--after--BorderTopWidth: var(--pf-c-form__field-group-header-toggle--BorderWidth--base);
  --pf-c-form__field-group-toggle-button--MarginTop: calc(var(--pf-global--spacer--form-element) * -1);
  --pf-c-form__field-group-toggle-button--MarginBottom: calc(var(--pf-global--spacer--form-element) * -1);
  --pf-c-form__field-group-toggle-icon--Transition: var(--pf-global--Transition);
  --pf-c-form__field-group-toggle-icon--MinWidth: var(--pf-global--FontSize--md);
  --pf-c-form__field-group-toggle-icon--Rotate: 0;
  --pf-c-form__field-group--m-expanded__toggle-icon--Rotate: 90deg;
  --pf-c-form__field-group-header--PaddingTop: var(--pf-global--spacer--md);
  --pf-c-form__field-group-header--PaddingBottom: var(--pf-global--spacer--md);
  --pf-c-form__field-group-header--GridColumn: 1 / 3;
  --pf-c-form__field-group__field-group__field-group-header--PaddingTop: var(--pf-global--spacer--lg);
  --pf-c-form__field-group__field-group__field-group-header--PaddingBottom: var(--pf-global--spacer--lg);
  --pf-c-form__field-group-toggle--field-group-header--GridColumn: 2 / 3;
  --pf-c-form__field-group__field-group--field-group__field-group-header--after--BorderTopWidth: var(--pf-c-form__field-group-header-toggle--BorderWidth--base);
  --pf-c-form__field-group-header-description--MarginTop: var(--pf-global--spacer--xs);
  --pf-c-form__field-group-header-description--Color: var(--pf-global--Color--200);
  --pf-c-form__field-group-header-actions--MarginTop: calc(var(--pf-global--spacer--form-element) * -1);
  --pf-c-form__field-group-header-actions--MarginBottom: calc(var(--pf-global--spacer--form-element) * -1);
  --pf-c-form__field-group-header-actions--MarginLeft: var(--pf-global--spacer--sm);
  --pf-c-form__field-group-body--PaddingTop: var(--pf-global--spacer--lg);
  --pf-c-form__field-group-body--PaddingBottom: var(--pf-global--spacer--lg);
  --pf-c-form__field-group-body--Gap: var(--pf-c-form--GridGap);
  --pf-c-form__field-group-body--GridColumn: 2 / 3;
  --pf-c-form__field-group__field-group__field-group-body--GridColumn: 1 / 3;
  --pf-c-form__field-group__field-group__field-group-toggle--field-group-body--GridColumn: 2 / 3;
  --pf-c-form__field-group-body__field-group--last-child--MarginBottom: calc(var(--pf-c-form__field-group-body--PaddingBottom) * -1);
  display: grid;
  gap: var(--pf-c-form--GridGap);
}
.pf-c-form.pf-m-horizontal.pf-m-align-right .pf-c-form__label {
  text-align: right;
}
@media (min-width: 768px) {
  .pf-c-form.pf-m-horizontal {
    --pf-c-form__group-label--PaddingBottom: 0;
  }
  .pf-c-form.pf-m-horizontal .pf-c-form__group {
    display: grid;
    grid-column-gap: var(--pf-c-form--m-horizontal__group-label--md--GridColumnGap);
    grid-template-columns: var(--pf-c-form--m-horizontal__group-label--md--GridColumnWidth) var(--pf-c-form--m-horizontal__group-control--md--GridColumnWidth);
  }
  .pf-c-form.pf-m-horizontal .pf-c-form__group-label {
    padding-top: var(--pf-c-form--m-horizontal__group-label--md--PaddingTop);
  }
  .pf-c-form.pf-m-horizontal .pf-c-form__group-label.pf-m-no-padding-top {
    --pf-c-form--m-horizontal__group-label--md--PaddingTop: 0;
  }
  .pf-c-form.pf-m-horizontal .pf-c-form__group-control {
    grid-column: 2;
  }
}
.pf-c-form.pf-m-limit-width {
  max-width: var(--pf-c-form--m-limit-width--MaxWidth);
}

.pf-c-form__group.pf-m-action {
  margin-top: var(--pf-c-form__group--m-action--MarginTop);
  overflow: hidden;
}

.pf-c-form__section {
  display: grid;
  gap: var(--pf-c-form__section--Gap);
}
.pf-c-form__section + .pf-c-form__group:not(.pf-m-action), .pf-c-form__section:not(:first-child) {
  margin-top: var(--pf-c-form__section--MarginTop);
}

.pf-c-form__section-title {
  margin-bottom: var(--pf-c-form__section-title--MarginBottom);
  font-size: var(--pf-c-form__section-title--FontSize);
  font-weight: var(--pf-c-form__section-title--FontWeight);
}

.pf-c-form__group-label {
  --pf-c-form__helper-text--MarginTop: 0;
  padding-bottom: var(--pf-c-form__group-label--PaddingBottom);
}
.pf-c-form__group-label.pf-m-info {
  display: flex;
  align-items: flex-end;
}

.pf-c-form__group-label-main {
  flex-grow: 1;
}

.pf-c-form__group-label-info {
  margin-left: var(--pf-c-form__group-label-info--MarginLeft);
  font-size: var(--pf-c-form__group-label-info--FontSize);
}

.pf-c-form__label {
  font-size: var(--pf-c-form__label--FontSize);
  line-height: var(--pf-c-form__label--LineHeight);
}
.pf-c-form__label::selection {
  background-color: none;
}
.pf-c-form__label:not(.pf-m-disabled):hover {
  cursor: pointer;
}
.pf-c-form__label.pf-m-disabled {
  color: var(--pf-c-form__label--m-disabled--Color);
}
.pf-c-form__label.pf-m-disabled:hover {
  cursor: not-allowed;
}

.pf-c-form__label-text {
  font-weight: var(--pf-c-form__label-text--FontWeight);
}

.pf-c-form__label-required {
  margin-left: var(--pf-c-form__label-required--MarginLeft);
  font-size: var(--pf-c-form__label-required--FontSize);
  color: var(--pf-c-form__label-required--Color);
}

.pf-c-form__group-label-help {
  padding-top: var(--pf-c-form__group-label-help--PaddingTop);
  padding-right: var(--pf-c-form__group-label-help--PaddingRight);
  padding-bottom: var(--pf-c-form__group-label-help--PaddingBottom);
  padding-left: var(--pf-c-form__group-label-help--PaddingLeft);
  margin-top: var(--pf-c-form__group-label-help--MarginTop);
  margin-right: var(--pf-c-form__group-label-help--MarginRight);
  margin-bottom: var(--pf-c-form__group-label-help--MarginBottom);
  margin-left: var(--pf-c-form__group-label-help--MarginLeft);
  font-size: var(--pf-c-form__group-label-help--FontSize);
  line-height: 1;
  color: var(--pf-c-form__group-label-help--Color);
  border: 0;
  transform: translateY(var(--pf-c-form__group-label-help--TranslateY));
}
.pf-c-form__group-label-help:hover {
  --pf-c-form__group-label-help--Color: var(--pf-c-form__group-label-help--hover--Color);
}
.pf-c-form__group-label-help:focus-within {
  --pf-c-form__group-label-help--Color: var(--pf-c-form__group-label-help--focus--Color);
}

.pf-c-form__group-control.pf-m-inline {
  display: flex;
  flex-flow: row wrap;
}
.pf-c-form__group-control.pf-m-inline > * {
  margin-right: var(--pf-c-form__group-control--m-inline--child--MarginRight);
}
.pf-c-form__group-control.pf-m-inline > :last-child {
  --pf-c-form__group-control--m-inline--child--MarginRight: 0;
}
.pf-c-form__group-control.pf-m-stack {
  display: grid;
  gap: var(--pf-c-grid__group-control--m-stack--Gap);
  --pf-c-form__helper-text--MarginTop: var(--pf-c-grid__group-control--m-stack__helper-text--MarginTop);
}
.pf-c-form__group-control .pf-c-form__helper-text:first-child {
  --pf-c-form__helper-text--MarginTop: 0;
  margin-bottom: var(--pf-c-form__group-control__helper-text--MarginBottom);
}

.pf-c-form__helper-text {
  margin-top: var(--pf-c-form__helper-text--MarginTop);
  font-size: var(--pf-c-form__helper-text--FontSize);
  color: var(--pf-c-form__helper-text--Color);
}
.pf-c-form__helper-text.pf-m-error {
  --pf-c-form__helper-text--Color: var(--pf-c-form__helper-text--m-error--Color);
}
.pf-c-form__helper-text.pf-m-success {
  --pf-c-form__helper-text--Color: var(--pf-c-form__helper-text--m-success--Color);
}
.pf-c-form__helper-text.pf-m-warning {
  --pf-c-form__helper-text--Color: var(--pf-c-form__helper-text--m-warning--Color);
}
.pf-c-form__helper-text.pf-m-inactive {
  display: none;
  visibility: hidden;
}
.pf-c-form__helper-text.pf-m-hidden {
  visibility: hidden;
  opacity: 0;
}

.pf-c-form__helper-text-icon {
  margin-right: var(--pf-c-form__helper-text-icon--MarginRight);
  font-size: var(--pf-c-form__helper-text-icon--FontSize);
}

.pf-c-form__fieldset {
  border: 0;
}

.pf-c-form__actions {
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--pf-c-form__actions--MarginTop);
  margin-right: var(--pf-c-form__actions--MarginRight);
  margin-bottom: var(--pf-c-form__actions--MarginBottom);
  margin-left: var(--pf-c-form__actions--MarginLeft);
}
.pf-c-form__actions > * {
  margin-top: var(--pf-c-form__actions--child--MarginTop);
  margin-right: var(--pf-c-form__actions--child--MarginRight);
  margin-bottom: var(--pf-c-form__actions--child--MarginBottom);
  margin-left: var(--pf-c-form__actions--child--MarginLeft);
}

.pf-c-form__field-group {
  --pf-c-form__field-group--BorderTopWidth: var(--pf-c-form__field-group--border-width-base);
  --pf-c-form__field-group--BorderTopWidth: var(--pf-c-form__field-group--border-width-base);
  display: grid;
  grid-template-columns: minmax(var(--pf-c-form__field-group--GridTemplateColumns--toggle), max-content) 1fr;
  border-top: var(--pf-c-form__field-group--BorderTopWidth) solid var(--pf-c-form__field-group--BorderTopColor);
  border-bottom: var(--pf-c-form__field-group--BorderBottomWidth) solid var(--pf-c-form__field-group--BorderBottomColor);
}
.pf-c-form__field-group:last-child {
  --pf-c-form__field-group--BorderBottomWidth: 0;
}
.pf-c-form__field-group + .pf-c-form__field-group, .pf-c-form__field-group:first-child {
  --pf-c-form__field-group--BorderTopWidth: 0;
}
.pf-c-form__field-group + .pf-c-form__field-group {
  margin-top: var(--pf-c-form__field-group--field-group--MarginTop);
}
.pf-c-form__field-group .pf-c-form__field-group {
  --pf-c-form__field-group-body--GridColumn: var(--pf-c-form__field-group__field-group__field-group-body--GridColumn);
  --pf-c-form__field-group-toggle--PaddingTop: var(--pf-c-form__field-group__field-group__field-group-toggle--PaddingTop);
  --pf-c-form__field-group-header--PaddingTop: var(--pf-c-form__field-group__field-group__field-group-header--PaddingTop);
  --pf-c-form__field-group-header--PaddingBottom: var(--pf-c-form__field-group__field-group__field-group-header--PaddingBottom);
  --pf-c-form__field-group-body--PaddingTop: 0;
}
.pf-c-form__field-group .pf-c-form__field-group .pf-c-form__field-group-toggle ~ .pf-c-form__field-group-body {
  --pf-c-form__field-group-body--GridColumn: var(--pf-c-form__field-group__field-group__field-group-toggle--field-group-body--GridColumn);
}
.pf-c-form__field-group.pf-m-expanded > .pf-c-form__field-group-toggle {
  --pf-c-form__field-group-toggle-icon--Rotate: var(--pf-c-form__field-group--m-expanded__toggle-icon--Rotate);
}

.pf-c-form__field-group-toggle {
  grid-column: 1/2;
  grid-row: 1/2;
  padding-top: var(--pf-c-form__field-group-toggle--PaddingTop);
  padding-right: var(--pf-c-form__field-group-toggle--PaddingRight);
}
.pf-c-form__field-group-toggle + .pf-c-form__field-group-header {
  --pf-c-form__field-group-header--GridColumn: var(--pf-c-form__field-group-toggle--field-group-header--GridColumn);
}

.pf-c-form__field-group-toggle-button {
  margin-top: var(--pf-c-form__field-group-toggle-button--MarginTop);
  margin-bottom: var(--pf-c-form__field-group-toggle-button--MarginBottom);
}

.pf-c-form__field-group-toggle-icon {
  display: inline-block;
  min-width: var(--pf-c-form__field-group-toggle-icon--MinWidth);
  text-align: center;
  transition: var(--pf-c-form__field-group-toggle-icon--Transition);
  transform: rotate(var(--pf-c-form__field-group-toggle-icon--Rotate));
}

.pf-c-form__field-group-header {
  grid-column: var(--pf-c-form__field-group-header--GridColumn);
  grid-row: 1/2;
  display: flex;
  align-items: flex-start;
  padding-top: var(--pf-c-form__field-group-header--PaddingTop);
  padding-bottom: var(--pf-c-form__field-group-header--PaddingBottom);
}

.pf-c-form__field-group-header-main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.pf-c-form__field-group-header-title {
  display: flex;
}

.pf-c-form__field-group-header-title-text {
  flex-grow: 1;
}

.pf-c-form__field-group-header-description {
  margin-top: var(--pf-c-form__field-group-header-description--MarginTop);
  color: var(--pf-c-form__field-group-header-description--Color);
}

.pf-c-form__field-group-header-actions {
  margin-top: var(--pf-c-form__field-group-header-actions--MarginTop);
  margin-bottom: var(--pf-c-form__field-group-header-actions--MarginBottom);
  margin-left: var(--pf-c-form__field-group-header-actions--MarginLeft);
  white-space: nowrap;
}

.pf-c-form__field-group-body {
  grid-column: var(--pf-c-form__field-group-body--GridColumn);
  display: grid;
  gap: var(--pf-c-form__field-group-body--Gap);
  padding-top: var(--pf-c-form__field-group-body--PaddingTop);
  padding-bottom: var(--pf-c-form__field-group-body--PaddingBottom);
}
.pf-c-form__field-group-body > .pf-c-form__field-group:first-child {
  --pf-c-form__field-group-toggle--PaddingTop: 0;
  --pf-c-form__field-group-header--PaddingTop: 0;
}
.pf-c-form__field-group-body > .pf-c-form__field-group:last-child {
  margin-bottom: var(--pf-c-form__field-group-body__field-group--last-child--MarginBottom);
}