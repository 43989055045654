.pf-topology-control-bar {
  position: absolute;
  bottom: var(--pf-global--spacer--md);
  left: var(--pf-global--spacer--xl);
}
.pf-topology-control-bar__button.pf-c-button.pf-m-tertiary {
  margin-right: var(--pf-global--spacer--xs);
  margin-top: var(--pf-global--spacer--xs);
  border: none;
  border-radius: var(--pf-global--BorderRadius--sm);
  box-shadow: var(--pf-global--BoxShadow--sm);
}
.pf-topology-control-bar__button.pf-c-button.pf-m-tertiary:not(.pf-m-disabled) {
  background-color: var(--pf-global--BackgroundColor--100);
}
.pf-topology-control-bar__button.pf-c-button.pf-m-tertiary:after {
  display: none;
}
.pf-topology-control-bar__button.pf-c-button.pf-m-tertiary:hover {
  border: none;
  box-shadow: var(--pf-global--BoxShadow--md);
}
