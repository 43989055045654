.pf-l-flex {
  --pf-l-flex--Display: flex;
  --pf-l-flex--FlexWrap: wrap;
  --pf-l-flex--AlignItems: baseline;
  --pf-l-flex--m-row--AlignItems: baseline;
  --pf-l-flex--m-row-reverse--AlignItems: baseline;
  --pf-l-flex--item--Order: 0;
  --pf-l-flex--spacer-base: var(--pf-global--spacer--md);
  --pf-l-flex--spacer: var(--pf-l-flex--spacer-base);
  --pf-l-flex--spacer--none: 0;
  --pf-l-flex--spacer--xs: var(--pf-global--spacer--xs);
  --pf-l-flex--spacer--sm: var(--pf-global--spacer--sm);
  --pf-l-flex--spacer--md: var(--pf-global--spacer--md);
  --pf-l-flex--spacer--lg: var(--pf-global--spacer--lg);
  --pf-l-flex--spacer--xl: var(--pf-global--spacer--xl);
  --pf-l-flex--spacer--2xl: var(--pf-global--spacer--2xl);
  --pf-l-flex--spacer--3xl: var(--pf-global--spacer--3xl);
  --pf-l-flex--spacer--4xl: var(--pf-global--spacer--4xl);
  display: var(--pf-l-flex--Display);
  flex-wrap: var(--pf-l-flex--FlexWrap);
  align-items: var(--pf-l-flex--AlignItems);
}
.pf-l-flex:last-child {
  --pf-l-flex--spacer: 0;
}

.pf-l-flex > * {
  --pf-l-flex--spacer: var(--pf-l-flex--spacer-base);
  order: var(--pf-l-flex--item--Order);
  max-width: 100%;
  margin-right: var(--pf-l-flex--spacer);
}
@media screen and (min-width: 576px) {
  .pf-l-flex > * {
    order: var(--pf-l-flex--item--Order-on-sm, var(--pf-l-flex--item--Order));
  }
}
@media screen and (min-width: 768px) {
  .pf-l-flex > * {
    order: var(--pf-l-flex--item--Order-on-md, var(--pf-l-flex--item--Order-on-sm, var(--pf-l-flex--item--Order)));
  }
}
@media screen and (min-width: 992px) {
  .pf-l-flex > * {
    order: var(--pf-l-flex--item--Order-on-lg, var(--pf-l-flex--item--Order-on-md, var(--pf-l-flex--item--Order-on-sm, var(--pf-l-flex--item--Order))));
  }
}
@media screen and (min-width: 1200px) {
  .pf-l-flex > * {
    order: var(--pf-l-flex--item--Order-on-xl, var(--pf-l-flex--item--Order-on-lg, var(--pf-l-flex--item--Order-on-md, var(--pf-l-flex--item--Order-on-sm, var(--pf-l-flex--item--Order)))));
  }
}
@media screen and (min-width: 1450px) {
  .pf-l-flex > * {
    order: var(--pf-l-flex--item--Order-on-2xl, var(--pf-l-flex--item--Order-on-xl, var(--pf-l-flex--item--Order-on-lg, var(--pf-l-flex--item--Order-on-md, var(--pf-l-flex--item--Order-on-sm, var(--pf-l-flex--item--Order))))));
  }
}
.pf-l-flex > *:last-child {
  --pf-l-flex--spacer: 0;
}

.pf-l-flex.pf-m-flex {
  display: var(--pf-l-flex--Display);
}
.pf-l-flex.pf-m-inline-flex {
  --pf-l-flex--Display: inline-flex;
}
.pf-l-flex.pf-m-column {
  flex-direction: column;
  align-items: normal;
}
.pf-l-flex.pf-m-column > * {
  margin: 0 0 var(--pf-l-flex--spacer) 0;
}
.pf-l-flex.pf-m-column-reverse {
  flex-direction: column-reverse;
  align-items: normal;
}
.pf-l-flex.pf-m-column-reverse > * {
  margin: var(--pf-l-flex--spacer) 0 0 0;
}
.pf-l-flex.pf-m-row {
  flex-direction: row;
  align-items: var(--pf-l-flex--m-row--AlignItems);
}
.pf-l-flex.pf-m-row > * {
  margin: 0 var(--pf-l-flex--spacer) 0 0;
}
.pf-l-flex.pf-m-row-reverse {
  flex-direction: row-reverse;
  align-items: var(--pf-l-flex--m-row-reverse--AlignItems);
}
.pf-l-flex.pf-m-row-reverse > * {
  margin: 0 0 0 var(--pf-l-flex--spacer);
}
.pf-l-flex.pf-m-wrap {
  flex-wrap: wrap;
}
.pf-l-flex.pf-m-wrap-reverse {
  flex-wrap: wrap-reverse;
}
.pf-l-flex.pf-m-nowrap {
  flex-wrap: nowrap;
}
.pf-l-flex.pf-m-justify-content-flex-start {
  justify-content: flex-start;
}
.pf-l-flex.pf-m-justify-content-flex-end {
  justify-content: flex-end;
}
.pf-l-flex.pf-m-justify-content-center {
  justify-content: center;
}
.pf-l-flex.pf-m-justify-content-space-between {
  justify-content: space-between;
}
.pf-l-flex.pf-m-justify-content-space-around {
  justify-content: space-around;
}
.pf-l-flex.pf-m-justify-content-space-evenly {
  justify-content: space-evenly;
}
.pf-l-flex.pf-m-align-items-flex-start {
  align-items: flex-start;
}
.pf-l-flex.pf-m-align-items-flex-end {
  align-items: flex-end;
}
.pf-l-flex.pf-m-align-items-center {
  align-items: center;
}
.pf-l-flex.pf-m-align-items-stretch {
  align-items: stretch;
}
.pf-l-flex.pf-m-align-items-baseline {
  align-items: baseline;
}
.pf-l-flex.pf-m-align-content-flex-start {
  align-content: flex-start;
}
.pf-l-flex.pf-m-align-content-flex-end {
  align-content: flex-end;
}
.pf-l-flex.pf-m-align-content-center {
  align-content: center;
}
.pf-l-flex.pf-m-align-content-stretch {
  align-content: stretch;
}
.pf-l-flex.pf-m-align-content-space-between {
  align-content: space-between;
}
.pf-l-flex.pf-m-align-content-space-around {
  align-content: space-around;
}
.pf-l-flex > .pf-m-align-right {
  margin-left: auto;
}
.pf-l-flex > .pf-m-align-left {
  margin-left: 0;
}
.pf-l-flex > .pf-m-grow {
  flex-grow: 1;
}
.pf-l-flex > .pf-m-shrink {
  flex-shrink: 1;
}
.pf-l-flex > .pf-m-full-width {
  width: 100%;
  margin-right: 0;
}
.pf-l-flex > .pf-m-flex-1 {
  flex: 1 0 0;
}
.pf-l-flex > .pf-m-flex-2 {
  flex: 2 0 0;
}
.pf-l-flex > .pf-m-flex-3 {
  flex: 3 0 0;
}
.pf-l-flex > .pf-m-flex-4 {
  flex: 4 0 0;
}
.pf-l-flex > .pf-m-flex-default {
  flex: 0 1 auto;
}
.pf-l-flex > .pf-m-flex-none {
  flex: none;
}
.pf-l-flex > .pf-m-align-self-flex-start {
  align-self: flex-start;
}
.pf-l-flex > .pf-m-align-self-flex-end {
  align-self: flex-end;
}
.pf-l-flex > .pf-m-align-self-center {
  align-self: center;
}
.pf-l-flex > .pf-m-align-self-baseline {
  align-self: baseline;
}
.pf-l-flex > .pf-m-align-self-stretch {
  align-self: stretch;
}
@media (min-width: 576px) {
  .pf-l-flex.pf-m-flex-on-sm {
    display: var(--pf-l-flex--Display);
  }
  .pf-l-flex.pf-m-inline-flex-on-sm {
    --pf-l-flex--Display: inline-flex;
  }
  .pf-l-flex.pf-m-column-on-sm {
    flex-direction: column;
    align-items: normal;
  }
  .pf-l-flex.pf-m-column-on-sm > * {
    margin: 0 0 var(--pf-l-flex--spacer) 0;
  }
  .pf-l-flex.pf-m-column-reverse-on-sm {
    flex-direction: column-reverse;
    align-items: normal;
  }
  .pf-l-flex.pf-m-column-reverse-on-sm > * {
    margin: var(--pf-l-flex--spacer) 0 0 0;
  }
  .pf-l-flex.pf-m-row-on-sm {
    flex-direction: row;
    align-items: var(--pf-l-flex--m-row--AlignItems);
  }
  .pf-l-flex.pf-m-row-on-sm > * {
    margin: 0 var(--pf-l-flex--spacer) 0 0;
  }
  .pf-l-flex.pf-m-row-reverse-on-sm {
    flex-direction: row-reverse;
    align-items: var(--pf-l-flex--m-row-reverse--AlignItems);
  }
  .pf-l-flex.pf-m-row-reverse-on-sm > * {
    margin: 0 0 0 var(--pf-l-flex--spacer);
  }
  .pf-l-flex.pf-m-wrap-on-sm {
    flex-wrap: wrap;
  }
  .pf-l-flex.pf-m-wrap-reverse-on-sm {
    flex-wrap: wrap-reverse;
  }
  .pf-l-flex.pf-m-nowrap-on-sm {
    flex-wrap: nowrap;
  }
  .pf-l-flex.pf-m-justify-content-flex-start-on-sm {
    justify-content: flex-start;
  }
  .pf-l-flex.pf-m-justify-content-flex-end-on-sm {
    justify-content: flex-end;
  }
  .pf-l-flex.pf-m-justify-content-center-on-sm {
    justify-content: center;
  }
  .pf-l-flex.pf-m-justify-content-space-between-on-sm {
    justify-content: space-between;
  }
  .pf-l-flex.pf-m-justify-content-space-around-on-sm {
    justify-content: space-around;
  }
  .pf-l-flex.pf-m-justify-content-space-evenly-on-sm {
    justify-content: space-evenly;
  }
  .pf-l-flex.pf-m-align-items-flex-start-on-sm {
    align-items: flex-start;
  }
  .pf-l-flex.pf-m-align-items-flex-end-on-sm {
    align-items: flex-end;
  }
  .pf-l-flex.pf-m-align-items-center-on-sm {
    align-items: center;
  }
  .pf-l-flex.pf-m-align-items-stretch-on-sm {
    align-items: stretch;
  }
  .pf-l-flex.pf-m-align-items-baseline-on-sm {
    align-items: baseline;
  }
  .pf-l-flex.pf-m-align-content-flex-start-on-sm {
    align-content: flex-start;
  }
  .pf-l-flex.pf-m-align-content-flex-end-on-sm {
    align-content: flex-end;
  }
  .pf-l-flex.pf-m-align-content-center-on-sm {
    align-content: center;
  }
  .pf-l-flex.pf-m-align-content-stretch-on-sm {
    align-content: stretch;
  }
  .pf-l-flex.pf-m-align-content-space-between-on-sm {
    align-content: space-between;
  }
  .pf-l-flex.pf-m-align-content-space-around-on-sm {
    align-content: space-around;
  }
  .pf-l-flex > .pf-m-align-right-on-sm {
    margin-left: auto;
  }
  .pf-l-flex > .pf-m-align-left-on-sm {
    margin-left: 0;
  }
  .pf-l-flex > .pf-m-grow-on-sm {
    flex-grow: 1;
  }
  .pf-l-flex > .pf-m-shrink-on-sm {
    flex-shrink: 1;
  }
  .pf-l-flex > .pf-m-full-width-on-sm {
    width: 100%;
    margin-right: 0;
  }
  .pf-l-flex > .pf-m-flex-1-on-sm {
    flex: 1 0 0;
  }
  .pf-l-flex > .pf-m-flex-2-on-sm {
    flex: 2 0 0;
  }
  .pf-l-flex > .pf-m-flex-3-on-sm {
    flex: 3 0 0;
  }
  .pf-l-flex > .pf-m-flex-4-on-sm {
    flex: 4 0 0;
  }
  .pf-l-flex > .pf-m-flex-default-on-sm {
    flex: 0 1 auto;
  }
  .pf-l-flex > .pf-m-flex-none-on-sm {
    flex: none;
  }
  .pf-l-flex > .pf-m-align-self-flex-start-on-sm {
    align-self: flex-start;
  }
  .pf-l-flex > .pf-m-align-self-flex-end-on-sm {
    align-self: flex-end;
  }
  .pf-l-flex > .pf-m-align-self-center-on-sm {
    align-self: center;
  }
  .pf-l-flex > .pf-m-align-self-baseline-on-sm {
    align-self: baseline;
  }
  .pf-l-flex > .pf-m-align-self-stretch-on-sm {
    align-self: stretch;
  }
}
@media (min-width: 768px) {
  .pf-l-flex.pf-m-flex-on-md {
    display: var(--pf-l-flex--Display);
  }
  .pf-l-flex.pf-m-inline-flex-on-md {
    --pf-l-flex--Display: inline-flex;
  }
  .pf-l-flex.pf-m-column-on-md {
    flex-direction: column;
    align-items: normal;
  }
  .pf-l-flex.pf-m-column-on-md > * {
    margin: 0 0 var(--pf-l-flex--spacer) 0;
  }
  .pf-l-flex.pf-m-column-reverse-on-md {
    flex-direction: column-reverse;
    align-items: normal;
  }
  .pf-l-flex.pf-m-column-reverse-on-md > * {
    margin: var(--pf-l-flex--spacer) 0 0 0;
  }
  .pf-l-flex.pf-m-row-on-md {
    flex-direction: row;
    align-items: var(--pf-l-flex--m-row--AlignItems);
  }
  .pf-l-flex.pf-m-row-on-md > * {
    margin: 0 var(--pf-l-flex--spacer) 0 0;
  }
  .pf-l-flex.pf-m-row-reverse-on-md {
    flex-direction: row-reverse;
    align-items: var(--pf-l-flex--m-row-reverse--AlignItems);
  }
  .pf-l-flex.pf-m-row-reverse-on-md > * {
    margin: 0 0 0 var(--pf-l-flex--spacer);
  }
  .pf-l-flex.pf-m-wrap-on-md {
    flex-wrap: wrap;
  }
  .pf-l-flex.pf-m-wrap-reverse-on-md {
    flex-wrap: wrap-reverse;
  }
  .pf-l-flex.pf-m-nowrap-on-md {
    flex-wrap: nowrap;
  }
  .pf-l-flex.pf-m-justify-content-flex-start-on-md {
    justify-content: flex-start;
  }
  .pf-l-flex.pf-m-justify-content-flex-end-on-md {
    justify-content: flex-end;
  }
  .pf-l-flex.pf-m-justify-content-center-on-md {
    justify-content: center;
  }
  .pf-l-flex.pf-m-justify-content-space-between-on-md {
    justify-content: space-between;
  }
  .pf-l-flex.pf-m-justify-content-space-around-on-md {
    justify-content: space-around;
  }
  .pf-l-flex.pf-m-justify-content-space-evenly-on-md {
    justify-content: space-evenly;
  }
  .pf-l-flex.pf-m-align-items-flex-start-on-md {
    align-items: flex-start;
  }
  .pf-l-flex.pf-m-align-items-flex-end-on-md {
    align-items: flex-end;
  }
  .pf-l-flex.pf-m-align-items-center-on-md {
    align-items: center;
  }
  .pf-l-flex.pf-m-align-items-stretch-on-md {
    align-items: stretch;
  }
  .pf-l-flex.pf-m-align-items-baseline-on-md {
    align-items: baseline;
  }
  .pf-l-flex.pf-m-align-content-flex-start-on-md {
    align-content: flex-start;
  }
  .pf-l-flex.pf-m-align-content-flex-end-on-md {
    align-content: flex-end;
  }
  .pf-l-flex.pf-m-align-content-center-on-md {
    align-content: center;
  }
  .pf-l-flex.pf-m-align-content-stretch-on-md {
    align-content: stretch;
  }
  .pf-l-flex.pf-m-align-content-space-between-on-md {
    align-content: space-between;
  }
  .pf-l-flex.pf-m-align-content-space-around-on-md {
    align-content: space-around;
  }
  .pf-l-flex > .pf-m-align-right-on-md {
    margin-left: auto;
  }
  .pf-l-flex > .pf-m-align-left-on-md {
    margin-left: 0;
  }
  .pf-l-flex > .pf-m-grow-on-md {
    flex-grow: 1;
  }
  .pf-l-flex > .pf-m-shrink-on-md {
    flex-shrink: 1;
  }
  .pf-l-flex > .pf-m-full-width-on-md {
    width: 100%;
    margin-right: 0;
  }
  .pf-l-flex > .pf-m-flex-1-on-md {
    flex: 1 0 0;
  }
  .pf-l-flex > .pf-m-flex-2-on-md {
    flex: 2 0 0;
  }
  .pf-l-flex > .pf-m-flex-3-on-md {
    flex: 3 0 0;
  }
  .pf-l-flex > .pf-m-flex-4-on-md {
    flex: 4 0 0;
  }
  .pf-l-flex > .pf-m-flex-default-on-md {
    flex: 0 1 auto;
  }
  .pf-l-flex > .pf-m-flex-none-on-md {
    flex: none;
  }
  .pf-l-flex > .pf-m-align-self-flex-start-on-md {
    align-self: flex-start;
  }
  .pf-l-flex > .pf-m-align-self-flex-end-on-md {
    align-self: flex-end;
  }
  .pf-l-flex > .pf-m-align-self-center-on-md {
    align-self: center;
  }
  .pf-l-flex > .pf-m-align-self-baseline-on-md {
    align-self: baseline;
  }
  .pf-l-flex > .pf-m-align-self-stretch-on-md {
    align-self: stretch;
  }
}
@media (min-width: 992px) {
  .pf-l-flex.pf-m-flex-on-lg {
    display: var(--pf-l-flex--Display);
  }
  .pf-l-flex.pf-m-inline-flex-on-lg {
    --pf-l-flex--Display: inline-flex;
  }
  .pf-l-flex.pf-m-column-on-lg {
    flex-direction: column;
    align-items: normal;
  }
  .pf-l-flex.pf-m-column-on-lg > * {
    margin: 0 0 var(--pf-l-flex--spacer) 0;
  }
  .pf-l-flex.pf-m-column-reverse-on-lg {
    flex-direction: column-reverse;
    align-items: normal;
  }
  .pf-l-flex.pf-m-column-reverse-on-lg > * {
    margin: var(--pf-l-flex--spacer) 0 0 0;
  }
  .pf-l-flex.pf-m-row-on-lg {
    flex-direction: row;
    align-items: var(--pf-l-flex--m-row--AlignItems);
  }
  .pf-l-flex.pf-m-row-on-lg > * {
    margin: 0 var(--pf-l-flex--spacer) 0 0;
  }
  .pf-l-flex.pf-m-row-reverse-on-lg {
    flex-direction: row-reverse;
    align-items: var(--pf-l-flex--m-row-reverse--AlignItems);
  }
  .pf-l-flex.pf-m-row-reverse-on-lg > * {
    margin: 0 0 0 var(--pf-l-flex--spacer);
  }
  .pf-l-flex.pf-m-wrap-on-lg {
    flex-wrap: wrap;
  }
  .pf-l-flex.pf-m-wrap-reverse-on-lg {
    flex-wrap: wrap-reverse;
  }
  .pf-l-flex.pf-m-nowrap-on-lg {
    flex-wrap: nowrap;
  }
  .pf-l-flex.pf-m-justify-content-flex-start-on-lg {
    justify-content: flex-start;
  }
  .pf-l-flex.pf-m-justify-content-flex-end-on-lg {
    justify-content: flex-end;
  }
  .pf-l-flex.pf-m-justify-content-center-on-lg {
    justify-content: center;
  }
  .pf-l-flex.pf-m-justify-content-space-between-on-lg {
    justify-content: space-between;
  }
  .pf-l-flex.pf-m-justify-content-space-around-on-lg {
    justify-content: space-around;
  }
  .pf-l-flex.pf-m-justify-content-space-evenly-on-lg {
    justify-content: space-evenly;
  }
  .pf-l-flex.pf-m-align-items-flex-start-on-lg {
    align-items: flex-start;
  }
  .pf-l-flex.pf-m-align-items-flex-end-on-lg {
    align-items: flex-end;
  }
  .pf-l-flex.pf-m-align-items-center-on-lg {
    align-items: center;
  }
  .pf-l-flex.pf-m-align-items-stretch-on-lg {
    align-items: stretch;
  }
  .pf-l-flex.pf-m-align-items-baseline-on-lg {
    align-items: baseline;
  }
  .pf-l-flex.pf-m-align-content-flex-start-on-lg {
    align-content: flex-start;
  }
  .pf-l-flex.pf-m-align-content-flex-end-on-lg {
    align-content: flex-end;
  }
  .pf-l-flex.pf-m-align-content-center-on-lg {
    align-content: center;
  }
  .pf-l-flex.pf-m-align-content-stretch-on-lg {
    align-content: stretch;
  }
  .pf-l-flex.pf-m-align-content-space-between-on-lg {
    align-content: space-between;
  }
  .pf-l-flex.pf-m-align-content-space-around-on-lg {
    align-content: space-around;
  }
  .pf-l-flex > .pf-m-align-right-on-lg {
    margin-left: auto;
  }
  .pf-l-flex > .pf-m-align-left-on-lg {
    margin-left: 0;
  }
  .pf-l-flex > .pf-m-grow-on-lg {
    flex-grow: 1;
  }
  .pf-l-flex > .pf-m-shrink-on-lg {
    flex-shrink: 1;
  }
  .pf-l-flex > .pf-m-full-width-on-lg {
    width: 100%;
    margin-right: 0;
  }
  .pf-l-flex > .pf-m-flex-1-on-lg {
    flex: 1 0 0;
  }
  .pf-l-flex > .pf-m-flex-2-on-lg {
    flex: 2 0 0;
  }
  .pf-l-flex > .pf-m-flex-3-on-lg {
    flex: 3 0 0;
  }
  .pf-l-flex > .pf-m-flex-4-on-lg {
    flex: 4 0 0;
  }
  .pf-l-flex > .pf-m-flex-default-on-lg {
    flex: 0 1 auto;
  }
  .pf-l-flex > .pf-m-flex-none-on-lg {
    flex: none;
  }
  .pf-l-flex > .pf-m-align-self-flex-start-on-lg {
    align-self: flex-start;
  }
  .pf-l-flex > .pf-m-align-self-flex-end-on-lg {
    align-self: flex-end;
  }
  .pf-l-flex > .pf-m-align-self-center-on-lg {
    align-self: center;
  }
  .pf-l-flex > .pf-m-align-self-baseline-on-lg {
    align-self: baseline;
  }
  .pf-l-flex > .pf-m-align-self-stretch-on-lg {
    align-self: stretch;
  }
}
@media (min-width: 1200px) {
  .pf-l-flex.pf-m-flex-on-xl {
    display: var(--pf-l-flex--Display);
  }
  .pf-l-flex.pf-m-inline-flex-on-xl {
    --pf-l-flex--Display: inline-flex;
  }
  .pf-l-flex.pf-m-column-on-xl {
    flex-direction: column;
    align-items: normal;
  }
  .pf-l-flex.pf-m-column-on-xl > * {
    margin: 0 0 var(--pf-l-flex--spacer) 0;
  }
  .pf-l-flex.pf-m-column-reverse-on-xl {
    flex-direction: column-reverse;
    align-items: normal;
  }
  .pf-l-flex.pf-m-column-reverse-on-xl > * {
    margin: var(--pf-l-flex--spacer) 0 0 0;
  }
  .pf-l-flex.pf-m-row-on-xl {
    flex-direction: row;
    align-items: var(--pf-l-flex--m-row--AlignItems);
  }
  .pf-l-flex.pf-m-row-on-xl > * {
    margin: 0 var(--pf-l-flex--spacer) 0 0;
  }
  .pf-l-flex.pf-m-row-reverse-on-xl {
    flex-direction: row-reverse;
    align-items: var(--pf-l-flex--m-row-reverse--AlignItems);
  }
  .pf-l-flex.pf-m-row-reverse-on-xl > * {
    margin: 0 0 0 var(--pf-l-flex--spacer);
  }
  .pf-l-flex.pf-m-wrap-on-xl {
    flex-wrap: wrap;
  }
  .pf-l-flex.pf-m-wrap-reverse-on-xl {
    flex-wrap: wrap-reverse;
  }
  .pf-l-flex.pf-m-nowrap-on-xl {
    flex-wrap: nowrap;
  }
  .pf-l-flex.pf-m-justify-content-flex-start-on-xl {
    justify-content: flex-start;
  }
  .pf-l-flex.pf-m-justify-content-flex-end-on-xl {
    justify-content: flex-end;
  }
  .pf-l-flex.pf-m-justify-content-center-on-xl {
    justify-content: center;
  }
  .pf-l-flex.pf-m-justify-content-space-between-on-xl {
    justify-content: space-between;
  }
  .pf-l-flex.pf-m-justify-content-space-around-on-xl {
    justify-content: space-around;
  }
  .pf-l-flex.pf-m-justify-content-space-evenly-on-xl {
    justify-content: space-evenly;
  }
  .pf-l-flex.pf-m-align-items-flex-start-on-xl {
    align-items: flex-start;
  }
  .pf-l-flex.pf-m-align-items-flex-end-on-xl {
    align-items: flex-end;
  }
  .pf-l-flex.pf-m-align-items-center-on-xl {
    align-items: center;
  }
  .pf-l-flex.pf-m-align-items-stretch-on-xl {
    align-items: stretch;
  }
  .pf-l-flex.pf-m-align-items-baseline-on-xl {
    align-items: baseline;
  }
  .pf-l-flex.pf-m-align-content-flex-start-on-xl {
    align-content: flex-start;
  }
  .pf-l-flex.pf-m-align-content-flex-end-on-xl {
    align-content: flex-end;
  }
  .pf-l-flex.pf-m-align-content-center-on-xl {
    align-content: center;
  }
  .pf-l-flex.pf-m-align-content-stretch-on-xl {
    align-content: stretch;
  }
  .pf-l-flex.pf-m-align-content-space-between-on-xl {
    align-content: space-between;
  }
  .pf-l-flex.pf-m-align-content-space-around-on-xl {
    align-content: space-around;
  }
  .pf-l-flex > .pf-m-align-right-on-xl {
    margin-left: auto;
  }
  .pf-l-flex > .pf-m-align-left-on-xl {
    margin-left: 0;
  }
  .pf-l-flex > .pf-m-grow-on-xl {
    flex-grow: 1;
  }
  .pf-l-flex > .pf-m-shrink-on-xl {
    flex-shrink: 1;
  }
  .pf-l-flex > .pf-m-full-width-on-xl {
    width: 100%;
    margin-right: 0;
  }
  .pf-l-flex > .pf-m-flex-1-on-xl {
    flex: 1 0 0;
  }
  .pf-l-flex > .pf-m-flex-2-on-xl {
    flex: 2 0 0;
  }
  .pf-l-flex > .pf-m-flex-3-on-xl {
    flex: 3 0 0;
  }
  .pf-l-flex > .pf-m-flex-4-on-xl {
    flex: 4 0 0;
  }
  .pf-l-flex > .pf-m-flex-default-on-xl {
    flex: 0 1 auto;
  }
  .pf-l-flex > .pf-m-flex-none-on-xl {
    flex: none;
  }
  .pf-l-flex > .pf-m-align-self-flex-start-on-xl {
    align-self: flex-start;
  }
  .pf-l-flex > .pf-m-align-self-flex-end-on-xl {
    align-self: flex-end;
  }
  .pf-l-flex > .pf-m-align-self-center-on-xl {
    align-self: center;
  }
  .pf-l-flex > .pf-m-align-self-baseline-on-xl {
    align-self: baseline;
  }
  .pf-l-flex > .pf-m-align-self-stretch-on-xl {
    align-self: stretch;
  }
}
@media (min-width: 1450px) {
  .pf-l-flex.pf-m-flex-on-2xl {
    display: var(--pf-l-flex--Display);
  }
  .pf-l-flex.pf-m-inline-flex-on-2xl {
    --pf-l-flex--Display: inline-flex;
  }
  .pf-l-flex.pf-m-column-on-2xl {
    flex-direction: column;
    align-items: normal;
  }
  .pf-l-flex.pf-m-column-on-2xl > * {
    margin: 0 0 var(--pf-l-flex--spacer) 0;
  }
  .pf-l-flex.pf-m-column-reverse-on-2xl {
    flex-direction: column-reverse;
    align-items: normal;
  }
  .pf-l-flex.pf-m-column-reverse-on-2xl > * {
    margin: var(--pf-l-flex--spacer) 0 0 0;
  }
  .pf-l-flex.pf-m-row-on-2xl {
    flex-direction: row;
    align-items: var(--pf-l-flex--m-row--AlignItems);
  }
  .pf-l-flex.pf-m-row-on-2xl > * {
    margin: 0 var(--pf-l-flex--spacer) 0 0;
  }
  .pf-l-flex.pf-m-row-reverse-on-2xl {
    flex-direction: row-reverse;
    align-items: var(--pf-l-flex--m-row-reverse--AlignItems);
  }
  .pf-l-flex.pf-m-row-reverse-on-2xl > * {
    margin: 0 0 0 var(--pf-l-flex--spacer);
  }
  .pf-l-flex.pf-m-wrap-on-2xl {
    flex-wrap: wrap;
  }
  .pf-l-flex.pf-m-wrap-reverse-on-2xl {
    flex-wrap: wrap-reverse;
  }
  .pf-l-flex.pf-m-nowrap-on-2xl {
    flex-wrap: nowrap;
  }
  .pf-l-flex.pf-m-justify-content-flex-start-on-2xl {
    justify-content: flex-start;
  }
  .pf-l-flex.pf-m-justify-content-flex-end-on-2xl {
    justify-content: flex-end;
  }
  .pf-l-flex.pf-m-justify-content-center-on-2xl {
    justify-content: center;
  }
  .pf-l-flex.pf-m-justify-content-space-between-on-2xl {
    justify-content: space-between;
  }
  .pf-l-flex.pf-m-justify-content-space-around-on-2xl {
    justify-content: space-around;
  }
  .pf-l-flex.pf-m-justify-content-space-evenly-on-2xl {
    justify-content: space-evenly;
  }
  .pf-l-flex.pf-m-align-items-flex-start-on-2xl {
    align-items: flex-start;
  }
  .pf-l-flex.pf-m-align-items-flex-end-on-2xl {
    align-items: flex-end;
  }
  .pf-l-flex.pf-m-align-items-center-on-2xl {
    align-items: center;
  }
  .pf-l-flex.pf-m-align-items-stretch-on-2xl {
    align-items: stretch;
  }
  .pf-l-flex.pf-m-align-items-baseline-on-2xl {
    align-items: baseline;
  }
  .pf-l-flex.pf-m-align-content-flex-start-on-2xl {
    align-content: flex-start;
  }
  .pf-l-flex.pf-m-align-content-flex-end-on-2xl {
    align-content: flex-end;
  }
  .pf-l-flex.pf-m-align-content-center-on-2xl {
    align-content: center;
  }
  .pf-l-flex.pf-m-align-content-stretch-on-2xl {
    align-content: stretch;
  }
  .pf-l-flex.pf-m-align-content-space-between-on-2xl {
    align-content: space-between;
  }
  .pf-l-flex.pf-m-align-content-space-around-on-2xl {
    align-content: space-around;
  }
  .pf-l-flex > .pf-m-align-right-on-2xl {
    margin-left: auto;
  }
  .pf-l-flex > .pf-m-align-left-on-2xl {
    margin-left: 0;
  }
  .pf-l-flex > .pf-m-grow-on-2xl {
    flex-grow: 1;
  }
  .pf-l-flex > .pf-m-shrink-on-2xl {
    flex-shrink: 1;
  }
  .pf-l-flex > .pf-m-full-width-on-2xl {
    width: 100%;
    margin-right: 0;
  }
  .pf-l-flex > .pf-m-flex-1-on-2xl {
    flex: 1 0 0;
  }
  .pf-l-flex > .pf-m-flex-2-on-2xl {
    flex: 2 0 0;
  }
  .pf-l-flex > .pf-m-flex-3-on-2xl {
    flex: 3 0 0;
  }
  .pf-l-flex > .pf-m-flex-4-on-2xl {
    flex: 4 0 0;
  }
  .pf-l-flex > .pf-m-flex-default-on-2xl {
    flex: 0 1 auto;
  }
  .pf-l-flex > .pf-m-flex-none-on-2xl {
    flex: none;
  }
  .pf-l-flex > .pf-m-align-self-flex-start-on-2xl {
    align-self: flex-start;
  }
  .pf-l-flex > .pf-m-align-self-flex-end-on-2xl {
    align-self: flex-end;
  }
  .pf-l-flex > .pf-m-align-self-center-on-2xl {
    align-self: center;
  }
  .pf-l-flex > .pf-m-align-self-baseline-on-2xl {
    align-self: baseline;
  }
  .pf-l-flex > .pf-m-align-self-stretch-on-2xl {
    align-self: stretch;
  }
}
.pf-l-flex.pf-m-space-items-none > * {
  --pf-l-flex--spacer: var(--pf-l-flex--spacer--none);
}
.pf-l-flex.pf-m-space-items-none > :last-child {
  --pf-l-flex--spacer: 0;
}
.pf-l-flex.pf-m-space-items-xs > * {
  --pf-l-flex--spacer: var(--pf-l-flex--spacer--xs);
}
.pf-l-flex.pf-m-space-items-xs > :last-child {
  --pf-l-flex--spacer: 0;
}
.pf-l-flex.pf-m-space-items-sm > * {
  --pf-l-flex--spacer: var(--pf-l-flex--spacer--sm);
}
.pf-l-flex.pf-m-space-items-sm > :last-child {
  --pf-l-flex--spacer: 0;
}
.pf-l-flex.pf-m-space-items-md > * {
  --pf-l-flex--spacer: var(--pf-l-flex--spacer--md);
}
.pf-l-flex.pf-m-space-items-md > :last-child {
  --pf-l-flex--spacer: 0;
}
.pf-l-flex.pf-m-space-items-lg > * {
  --pf-l-flex--spacer: var(--pf-l-flex--spacer--lg);
}
.pf-l-flex.pf-m-space-items-lg > :last-child {
  --pf-l-flex--spacer: 0;
}
.pf-l-flex.pf-m-space-items-xl > * {
  --pf-l-flex--spacer: var(--pf-l-flex--spacer--xl);
}
.pf-l-flex.pf-m-space-items-xl > :last-child {
  --pf-l-flex--spacer: 0;
}
.pf-l-flex.pf-m-space-items-2xl > * {
  --pf-l-flex--spacer: var(--pf-l-flex--spacer--2xl);
}
.pf-l-flex.pf-m-space-items-2xl > :last-child {
  --pf-l-flex--spacer: 0;
}
.pf-l-flex.pf-m-space-items-3xl > * {
  --pf-l-flex--spacer: var(--pf-l-flex--spacer--3xl);
}
.pf-l-flex.pf-m-space-items-3xl > :last-child {
  --pf-l-flex--spacer: 0;
}
.pf-l-flex.pf-m-space-items-4xl > * {
  --pf-l-flex--spacer: var(--pf-l-flex--spacer--4xl);
}
.pf-l-flex.pf-m-space-items-4xl > :last-child {
  --pf-l-flex--spacer: 0;
}
@media (min-width: 576px) {
  .pf-l-flex.pf-m-space-items-none-on-sm > * {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--none);
  }
  .pf-l-flex.pf-m-space-items-none-on-sm > :last-child {
    --pf-l-flex--spacer: 0;
  }
  .pf-l-flex.pf-m-space-items-xs-on-sm > * {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--xs);
  }
  .pf-l-flex.pf-m-space-items-xs-on-sm > :last-child {
    --pf-l-flex--spacer: 0;
  }
  .pf-l-flex.pf-m-space-items-sm-on-sm > * {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--sm);
  }
  .pf-l-flex.pf-m-space-items-sm-on-sm > :last-child {
    --pf-l-flex--spacer: 0;
  }
  .pf-l-flex.pf-m-space-items-md-on-sm > * {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--md);
  }
  .pf-l-flex.pf-m-space-items-md-on-sm > :last-child {
    --pf-l-flex--spacer: 0;
  }
  .pf-l-flex.pf-m-space-items-lg-on-sm > * {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--lg);
  }
  .pf-l-flex.pf-m-space-items-lg-on-sm > :last-child {
    --pf-l-flex--spacer: 0;
  }
  .pf-l-flex.pf-m-space-items-xl-on-sm > * {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--xl);
  }
  .pf-l-flex.pf-m-space-items-xl-on-sm > :last-child {
    --pf-l-flex--spacer: 0;
  }
  .pf-l-flex.pf-m-space-items-2xl-on-sm > * {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--2xl);
  }
  .pf-l-flex.pf-m-space-items-2xl-on-sm > :last-child {
    --pf-l-flex--spacer: 0;
  }
  .pf-l-flex.pf-m-space-items-3xl-on-sm > * {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--3xl);
  }
  .pf-l-flex.pf-m-space-items-3xl-on-sm > :last-child {
    --pf-l-flex--spacer: 0;
  }
  .pf-l-flex.pf-m-space-items-4xl-on-sm > * {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--4xl);
  }
  .pf-l-flex.pf-m-space-items-4xl-on-sm > :last-child {
    --pf-l-flex--spacer: 0;
  }
}
@media (min-width: 768px) {
  .pf-l-flex.pf-m-space-items-none-on-md > * {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--none);
  }
  .pf-l-flex.pf-m-space-items-none-on-md > :last-child {
    --pf-l-flex--spacer: 0;
  }
  .pf-l-flex.pf-m-space-items-xs-on-md > * {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--xs);
  }
  .pf-l-flex.pf-m-space-items-xs-on-md > :last-child {
    --pf-l-flex--spacer: 0;
  }
  .pf-l-flex.pf-m-space-items-sm-on-md > * {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--sm);
  }
  .pf-l-flex.pf-m-space-items-sm-on-md > :last-child {
    --pf-l-flex--spacer: 0;
  }
  .pf-l-flex.pf-m-space-items-md-on-md > * {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--md);
  }
  .pf-l-flex.pf-m-space-items-md-on-md > :last-child {
    --pf-l-flex--spacer: 0;
  }
  .pf-l-flex.pf-m-space-items-lg-on-md > * {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--lg);
  }
  .pf-l-flex.pf-m-space-items-lg-on-md > :last-child {
    --pf-l-flex--spacer: 0;
  }
  .pf-l-flex.pf-m-space-items-xl-on-md > * {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--xl);
  }
  .pf-l-flex.pf-m-space-items-xl-on-md > :last-child {
    --pf-l-flex--spacer: 0;
  }
  .pf-l-flex.pf-m-space-items-2xl-on-md > * {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--2xl);
  }
  .pf-l-flex.pf-m-space-items-2xl-on-md > :last-child {
    --pf-l-flex--spacer: 0;
  }
  .pf-l-flex.pf-m-space-items-3xl-on-md > * {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--3xl);
  }
  .pf-l-flex.pf-m-space-items-3xl-on-md > :last-child {
    --pf-l-flex--spacer: 0;
  }
  .pf-l-flex.pf-m-space-items-4xl-on-md > * {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--4xl);
  }
  .pf-l-flex.pf-m-space-items-4xl-on-md > :last-child {
    --pf-l-flex--spacer: 0;
  }
}
@media (min-width: 992px) {
  .pf-l-flex.pf-m-space-items-none-on-lg > * {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--none);
  }
  .pf-l-flex.pf-m-space-items-none-on-lg > :last-child {
    --pf-l-flex--spacer: 0;
  }
  .pf-l-flex.pf-m-space-items-xs-on-lg > * {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--xs);
  }
  .pf-l-flex.pf-m-space-items-xs-on-lg > :last-child {
    --pf-l-flex--spacer: 0;
  }
  .pf-l-flex.pf-m-space-items-sm-on-lg > * {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--sm);
  }
  .pf-l-flex.pf-m-space-items-sm-on-lg > :last-child {
    --pf-l-flex--spacer: 0;
  }
  .pf-l-flex.pf-m-space-items-md-on-lg > * {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--md);
  }
  .pf-l-flex.pf-m-space-items-md-on-lg > :last-child {
    --pf-l-flex--spacer: 0;
  }
  .pf-l-flex.pf-m-space-items-lg-on-lg > * {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--lg);
  }
  .pf-l-flex.pf-m-space-items-lg-on-lg > :last-child {
    --pf-l-flex--spacer: 0;
  }
  .pf-l-flex.pf-m-space-items-xl-on-lg > * {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--xl);
  }
  .pf-l-flex.pf-m-space-items-xl-on-lg > :last-child {
    --pf-l-flex--spacer: 0;
  }
  .pf-l-flex.pf-m-space-items-2xl-on-lg > * {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--2xl);
  }
  .pf-l-flex.pf-m-space-items-2xl-on-lg > :last-child {
    --pf-l-flex--spacer: 0;
  }
  .pf-l-flex.pf-m-space-items-3xl-on-lg > * {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--3xl);
  }
  .pf-l-flex.pf-m-space-items-3xl-on-lg > :last-child {
    --pf-l-flex--spacer: 0;
  }
  .pf-l-flex.pf-m-space-items-4xl-on-lg > * {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--4xl);
  }
  .pf-l-flex.pf-m-space-items-4xl-on-lg > :last-child {
    --pf-l-flex--spacer: 0;
  }
}
@media (min-width: 1200px) {
  .pf-l-flex.pf-m-space-items-none-on-xl > * {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--none);
  }
  .pf-l-flex.pf-m-space-items-none-on-xl > :last-child {
    --pf-l-flex--spacer: 0;
  }
  .pf-l-flex.pf-m-space-items-xs-on-xl > * {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--xs);
  }
  .pf-l-flex.pf-m-space-items-xs-on-xl > :last-child {
    --pf-l-flex--spacer: 0;
  }
  .pf-l-flex.pf-m-space-items-sm-on-xl > * {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--sm);
  }
  .pf-l-flex.pf-m-space-items-sm-on-xl > :last-child {
    --pf-l-flex--spacer: 0;
  }
  .pf-l-flex.pf-m-space-items-md-on-xl > * {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--md);
  }
  .pf-l-flex.pf-m-space-items-md-on-xl > :last-child {
    --pf-l-flex--spacer: 0;
  }
  .pf-l-flex.pf-m-space-items-lg-on-xl > * {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--lg);
  }
  .pf-l-flex.pf-m-space-items-lg-on-xl > :last-child {
    --pf-l-flex--spacer: 0;
  }
  .pf-l-flex.pf-m-space-items-xl-on-xl > * {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--xl);
  }
  .pf-l-flex.pf-m-space-items-xl-on-xl > :last-child {
    --pf-l-flex--spacer: 0;
  }
  .pf-l-flex.pf-m-space-items-2xl-on-xl > * {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--2xl);
  }
  .pf-l-flex.pf-m-space-items-2xl-on-xl > :last-child {
    --pf-l-flex--spacer: 0;
  }
  .pf-l-flex.pf-m-space-items-3xl-on-xl > * {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--3xl);
  }
  .pf-l-flex.pf-m-space-items-3xl-on-xl > :last-child {
    --pf-l-flex--spacer: 0;
  }
  .pf-l-flex.pf-m-space-items-4xl-on-xl > * {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--4xl);
  }
  .pf-l-flex.pf-m-space-items-4xl-on-xl > :last-child {
    --pf-l-flex--spacer: 0;
  }
}
@media (min-width: 1450px) {
  .pf-l-flex.pf-m-space-items-none-on-2xl > * {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--none);
  }
  .pf-l-flex.pf-m-space-items-none-on-2xl > :last-child {
    --pf-l-flex--spacer: 0;
  }
  .pf-l-flex.pf-m-space-items-xs-on-2xl > * {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--xs);
  }
  .pf-l-flex.pf-m-space-items-xs-on-2xl > :last-child {
    --pf-l-flex--spacer: 0;
  }
  .pf-l-flex.pf-m-space-items-sm-on-2xl > * {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--sm);
  }
  .pf-l-flex.pf-m-space-items-sm-on-2xl > :last-child {
    --pf-l-flex--spacer: 0;
  }
  .pf-l-flex.pf-m-space-items-md-on-2xl > * {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--md);
  }
  .pf-l-flex.pf-m-space-items-md-on-2xl > :last-child {
    --pf-l-flex--spacer: 0;
  }
  .pf-l-flex.pf-m-space-items-lg-on-2xl > * {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--lg);
  }
  .pf-l-flex.pf-m-space-items-lg-on-2xl > :last-child {
    --pf-l-flex--spacer: 0;
  }
  .pf-l-flex.pf-m-space-items-xl-on-2xl > * {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--xl);
  }
  .pf-l-flex.pf-m-space-items-xl-on-2xl > :last-child {
    --pf-l-flex--spacer: 0;
  }
  .pf-l-flex.pf-m-space-items-2xl-on-2xl > * {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--2xl);
  }
  .pf-l-flex.pf-m-space-items-2xl-on-2xl > :last-child {
    --pf-l-flex--spacer: 0;
  }
  .pf-l-flex.pf-m-space-items-3xl-on-2xl > * {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--3xl);
  }
  .pf-l-flex.pf-m-space-items-3xl-on-2xl > :last-child {
    --pf-l-flex--spacer: 0;
  }
  .pf-l-flex.pf-m-space-items-4xl-on-2xl > * {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--4xl);
  }
  .pf-l-flex.pf-m-space-items-4xl-on-2xl > :last-child {
    --pf-l-flex--spacer: 0;
  }
}
.pf-l-flex .pf-m-spacer-none {
  --pf-l-flex--spacer: var(--pf-l-flex--spacer--none);
}
.pf-l-flex .pf-m-spacer-none:last-child {
  --pf-l-flex--spacer: var(--pf-l-flex--spacer--none);
}
.pf-l-flex .pf-m-spacer-xs {
  --pf-l-flex--spacer: var(--pf-l-flex--spacer--xs);
}
.pf-l-flex .pf-m-spacer-xs:last-child {
  --pf-l-flex--spacer: var(--pf-l-flex--spacer--xs);
}
.pf-l-flex .pf-m-spacer-sm {
  --pf-l-flex--spacer: var(--pf-l-flex--spacer--sm);
}
.pf-l-flex .pf-m-spacer-sm:last-child {
  --pf-l-flex--spacer: var(--pf-l-flex--spacer--sm);
}
.pf-l-flex .pf-m-spacer-md {
  --pf-l-flex--spacer: var(--pf-l-flex--spacer--md);
}
.pf-l-flex .pf-m-spacer-md:last-child {
  --pf-l-flex--spacer: var(--pf-l-flex--spacer--md);
}
.pf-l-flex .pf-m-spacer-lg {
  --pf-l-flex--spacer: var(--pf-l-flex--spacer--lg);
}
.pf-l-flex .pf-m-spacer-lg:last-child {
  --pf-l-flex--spacer: var(--pf-l-flex--spacer--lg);
}
.pf-l-flex .pf-m-spacer-xl {
  --pf-l-flex--spacer: var(--pf-l-flex--spacer--xl);
}
.pf-l-flex .pf-m-spacer-xl:last-child {
  --pf-l-flex--spacer: var(--pf-l-flex--spacer--xl);
}
.pf-l-flex .pf-m-spacer-2xl {
  --pf-l-flex--spacer: var(--pf-l-flex--spacer--2xl);
}
.pf-l-flex .pf-m-spacer-2xl:last-child {
  --pf-l-flex--spacer: var(--pf-l-flex--spacer--2xl);
}
.pf-l-flex .pf-m-spacer-3xl {
  --pf-l-flex--spacer: var(--pf-l-flex--spacer--3xl);
}
.pf-l-flex .pf-m-spacer-3xl:last-child {
  --pf-l-flex--spacer: var(--pf-l-flex--spacer--3xl);
}
.pf-l-flex .pf-m-spacer-4xl {
  --pf-l-flex--spacer: var(--pf-l-flex--spacer--4xl);
}
.pf-l-flex .pf-m-spacer-4xl:last-child {
  --pf-l-flex--spacer: var(--pf-l-flex--spacer--4xl);
}
@media (min-width: 576px) {
  .pf-l-flex .pf-m-spacer-none-on-sm {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--none);
  }
  .pf-l-flex .pf-m-spacer-none-on-sm:last-child {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--none);
  }
  .pf-l-flex .pf-m-spacer-xs-on-sm {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--xs);
  }
  .pf-l-flex .pf-m-spacer-xs-on-sm:last-child {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--xs);
  }
  .pf-l-flex .pf-m-spacer-sm-on-sm {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--sm);
  }
  .pf-l-flex .pf-m-spacer-sm-on-sm:last-child {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--sm);
  }
  .pf-l-flex .pf-m-spacer-md-on-sm {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--md);
  }
  .pf-l-flex .pf-m-spacer-md-on-sm:last-child {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--md);
  }
  .pf-l-flex .pf-m-spacer-lg-on-sm {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--lg);
  }
  .pf-l-flex .pf-m-spacer-lg-on-sm:last-child {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--lg);
  }
  .pf-l-flex .pf-m-spacer-xl-on-sm {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--xl);
  }
  .pf-l-flex .pf-m-spacer-xl-on-sm:last-child {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--xl);
  }
  .pf-l-flex .pf-m-spacer-2xl-on-sm {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--2xl);
  }
  .pf-l-flex .pf-m-spacer-2xl-on-sm:last-child {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--2xl);
  }
  .pf-l-flex .pf-m-spacer-3xl-on-sm {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--3xl);
  }
  .pf-l-flex .pf-m-spacer-3xl-on-sm:last-child {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--3xl);
  }
  .pf-l-flex .pf-m-spacer-4xl-on-sm {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--4xl);
  }
  .pf-l-flex .pf-m-spacer-4xl-on-sm:last-child {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--4xl);
  }
}
@media (min-width: 768px) {
  .pf-l-flex .pf-m-spacer-none-on-md {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--none);
  }
  .pf-l-flex .pf-m-spacer-none-on-md:last-child {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--none);
  }
  .pf-l-flex .pf-m-spacer-xs-on-md {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--xs);
  }
  .pf-l-flex .pf-m-spacer-xs-on-md:last-child {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--xs);
  }
  .pf-l-flex .pf-m-spacer-sm-on-md {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--sm);
  }
  .pf-l-flex .pf-m-spacer-sm-on-md:last-child {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--sm);
  }
  .pf-l-flex .pf-m-spacer-md-on-md {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--md);
  }
  .pf-l-flex .pf-m-spacer-md-on-md:last-child {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--md);
  }
  .pf-l-flex .pf-m-spacer-lg-on-md {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--lg);
  }
  .pf-l-flex .pf-m-spacer-lg-on-md:last-child {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--lg);
  }
  .pf-l-flex .pf-m-spacer-xl-on-md {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--xl);
  }
  .pf-l-flex .pf-m-spacer-xl-on-md:last-child {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--xl);
  }
  .pf-l-flex .pf-m-spacer-2xl-on-md {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--2xl);
  }
  .pf-l-flex .pf-m-spacer-2xl-on-md:last-child {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--2xl);
  }
  .pf-l-flex .pf-m-spacer-3xl-on-md {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--3xl);
  }
  .pf-l-flex .pf-m-spacer-3xl-on-md:last-child {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--3xl);
  }
  .pf-l-flex .pf-m-spacer-4xl-on-md {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--4xl);
  }
  .pf-l-flex .pf-m-spacer-4xl-on-md:last-child {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--4xl);
  }
}
@media (min-width: 992px) {
  .pf-l-flex .pf-m-spacer-none-on-lg {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--none);
  }
  .pf-l-flex .pf-m-spacer-none-on-lg:last-child {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--none);
  }
  .pf-l-flex .pf-m-spacer-xs-on-lg {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--xs);
  }
  .pf-l-flex .pf-m-spacer-xs-on-lg:last-child {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--xs);
  }
  .pf-l-flex .pf-m-spacer-sm-on-lg {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--sm);
  }
  .pf-l-flex .pf-m-spacer-sm-on-lg:last-child {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--sm);
  }
  .pf-l-flex .pf-m-spacer-md-on-lg {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--md);
  }
  .pf-l-flex .pf-m-spacer-md-on-lg:last-child {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--md);
  }
  .pf-l-flex .pf-m-spacer-lg-on-lg {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--lg);
  }
  .pf-l-flex .pf-m-spacer-lg-on-lg:last-child {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--lg);
  }
  .pf-l-flex .pf-m-spacer-xl-on-lg {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--xl);
  }
  .pf-l-flex .pf-m-spacer-xl-on-lg:last-child {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--xl);
  }
  .pf-l-flex .pf-m-spacer-2xl-on-lg {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--2xl);
  }
  .pf-l-flex .pf-m-spacer-2xl-on-lg:last-child {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--2xl);
  }
  .pf-l-flex .pf-m-spacer-3xl-on-lg {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--3xl);
  }
  .pf-l-flex .pf-m-spacer-3xl-on-lg:last-child {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--3xl);
  }
  .pf-l-flex .pf-m-spacer-4xl-on-lg {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--4xl);
  }
  .pf-l-flex .pf-m-spacer-4xl-on-lg:last-child {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--4xl);
  }
}
@media (min-width: 1200px) {
  .pf-l-flex .pf-m-spacer-none-on-xl {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--none);
  }
  .pf-l-flex .pf-m-spacer-none-on-xl:last-child {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--none);
  }
  .pf-l-flex .pf-m-spacer-xs-on-xl {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--xs);
  }
  .pf-l-flex .pf-m-spacer-xs-on-xl:last-child {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--xs);
  }
  .pf-l-flex .pf-m-spacer-sm-on-xl {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--sm);
  }
  .pf-l-flex .pf-m-spacer-sm-on-xl:last-child {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--sm);
  }
  .pf-l-flex .pf-m-spacer-md-on-xl {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--md);
  }
  .pf-l-flex .pf-m-spacer-md-on-xl:last-child {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--md);
  }
  .pf-l-flex .pf-m-spacer-lg-on-xl {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--lg);
  }
  .pf-l-flex .pf-m-spacer-lg-on-xl:last-child {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--lg);
  }
  .pf-l-flex .pf-m-spacer-xl-on-xl {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--xl);
  }
  .pf-l-flex .pf-m-spacer-xl-on-xl:last-child {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--xl);
  }
  .pf-l-flex .pf-m-spacer-2xl-on-xl {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--2xl);
  }
  .pf-l-flex .pf-m-spacer-2xl-on-xl:last-child {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--2xl);
  }
  .pf-l-flex .pf-m-spacer-3xl-on-xl {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--3xl);
  }
  .pf-l-flex .pf-m-spacer-3xl-on-xl:last-child {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--3xl);
  }
  .pf-l-flex .pf-m-spacer-4xl-on-xl {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--4xl);
  }
  .pf-l-flex .pf-m-spacer-4xl-on-xl:last-child {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--4xl);
  }
}
@media (min-width: 1450px) {
  .pf-l-flex .pf-m-spacer-none-on-2xl {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--none);
  }
  .pf-l-flex .pf-m-spacer-none-on-2xl:last-child {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--none);
  }
  .pf-l-flex .pf-m-spacer-xs-on-2xl {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--xs);
  }
  .pf-l-flex .pf-m-spacer-xs-on-2xl:last-child {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--xs);
  }
  .pf-l-flex .pf-m-spacer-sm-on-2xl {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--sm);
  }
  .pf-l-flex .pf-m-spacer-sm-on-2xl:last-child {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--sm);
  }
  .pf-l-flex .pf-m-spacer-md-on-2xl {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--md);
  }
  .pf-l-flex .pf-m-spacer-md-on-2xl:last-child {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--md);
  }
  .pf-l-flex .pf-m-spacer-lg-on-2xl {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--lg);
  }
  .pf-l-flex .pf-m-spacer-lg-on-2xl:last-child {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--lg);
  }
  .pf-l-flex .pf-m-spacer-xl-on-2xl {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--xl);
  }
  .pf-l-flex .pf-m-spacer-xl-on-2xl:last-child {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--xl);
  }
  .pf-l-flex .pf-m-spacer-2xl-on-2xl {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--2xl);
  }
  .pf-l-flex .pf-m-spacer-2xl-on-2xl:last-child {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--2xl);
  }
  .pf-l-flex .pf-m-spacer-3xl-on-2xl {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--3xl);
  }
  .pf-l-flex .pf-m-spacer-3xl-on-2xl:last-child {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--3xl);
  }
  .pf-l-flex .pf-m-spacer-4xl-on-2xl {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--4xl);
  }
  .pf-l-flex .pf-m-spacer-4xl-on-2xl:last-child {
    --pf-l-flex--spacer: var(--pf-l-flex--spacer--4xl);
  }
}