.pf-c-form-control {
  --pf-global--Color--100: var(--pf-global--Color--dark-100);
  --pf-global--Color--200: var(--pf-global--Color--dark-200);
  --pf-global--BorderColor--100: var(--pf-global--BorderColor--dark-100);
  --pf-global--primary-color--100: var(--pf-global--primary-color--dark-100);
  --pf-global--link--Color: var(--pf-global--link--Color--dark);
  --pf-global--link--Color--hover: var(--pf-global--link--Color--dark--hover);
  --pf-global--BackgroundColor--100: var(--pf-global--BackgroundColor--light-100);
}

.pf-c-form-control {
  --pf-c-form-control--FontSize: var(--pf-global--FontSize--md);
  --pf-c-form-control--LineHeight: var(--pf-global--LineHeight--md);
  --pf-c-form-control--BorderWidth: var(--pf-global--BorderWidth--sm);
  --pf-c-form-control--BorderTopColor: var(--pf-global--BorderColor--300);
  --pf-c-form-control--BorderRightColor: var(--pf-global--BorderColor--300);
  --pf-c-form-control--BorderBottomColor: var(--pf-global--BorderColor--200);
  --pf-c-form-control--BorderLeftColor: var(--pf-global--BorderColor--300);
  --pf-c-form-control--BorderRadius: 0;
  --pf-c-form-control--BackgroundColor: var(--pf-global--BackgroundColor--100);
  --pf-c-form-control--Width: 100%;
  --pf-c-form-control--Height: calc(var(--pf-c-form-control--FontSize) * var(--pf-c-form-control--LineHeight) + var(--pf-c-form-control--BorderWidth) * 2 + var(--pf-c-form-control--PaddingTop) + var(--pf-c-form-control--PaddingBottom));
  --pf-c-form-control--inset--base: var(--pf-global--spacer--sm);
  --pf-c-form-control--PaddingTop: calc(var(--pf-global--spacer--form-element) - var(--pf-global--BorderWidth--sm));
  --pf-c-form-control--PaddingBottom: calc(var(--pf-global--spacer--form-element) - var(--pf-global--BorderWidth--sm));
  --pf-c-form-control--PaddingRight: var(--pf-c-form-control--inset--base);
  --pf-c-form-control--PaddingLeft: var(--pf-c-form-control--inset--base);
  --pf-c-form-control--hover--BorderBottomColor: var(--pf-global--primary-color--100);
  --pf-c-form-control--focus--BorderBottomWidth: var(--pf-global--BorderWidth--md);
  --pf-c-form-control--focus--PaddingBottom: calc(var(--pf-global--spacer--form-element) - var(--pf-c-form-control--focus--BorderBottomWidth));
  --pf-c-form-control--focus--BorderBottomColor: var(--pf-global--primary-color--100);
  --pf-c-form-control--m-expanded--BorderBottomWidth: var(--pf-global--BorderWidth--md);
  --pf-c-form-control--m-expanded--PaddingBottom: calc(var(--pf-global--spacer--form-element) - var(--pf-c-form-control--focus--BorderBottomWidth));
  --pf-c-form-control--m-expanded--BorderBottomColor: var(--pf-global--primary-color--100);
  --pf-c-form-control--placeholder--Color: var(--pf-global--Color--dark-200);
  --pf-c-form-control--disabled--Color: var(--pf-global--disabled-color--100);
  --pf-c-form-control--disabled--BackgroundColor: var(--pf-global--disabled-color--300);
  --pf-c-form-control--disabled--BorderColor: transparent;
  --pf-c-form-control--readonly--BackgroundColor: var(--pf-global--disabled-color--300);
  --pf-c-form-control--readonly--hover--BorderBottomColor: var(--pf-global--BorderColor--200);
  --pf-c-form-control--readonly--focus--PaddingBottom: calc(var(--pf-global--spacer--form-element) - var(--pf-global--BorderWidth--sm));
  --pf-c-form-control--readonly--focus--BorderBottomWidth: var(--pf-global--BorderWidth--sm);
  --pf-c-form-control--readonly--focus--BorderBottomColor: var(--pf-global--BorderColor--200);
  --pf-c-form-control--success--BorderBottomWidth: var(--pf-global--BorderWidth--md);
  --pf-c-form-control--success--PaddingBottom: calc(var(--pf-global--spacer--form-element) - var(--pf-c-form-control--success--BorderBottomWidth));
  --pf-c-form-control--success--BorderBottomColor: var(--pf-global--success-color--100);
  --pf-c-form-control--success--PaddingRight: var(--pf-global--spacer--xl);
  --pf-c-form-control--success--BackgroundPositionX: calc(100% - var(--pf-c-form-control--PaddingLeft));
  --pf-c-form-control--success--BackgroundPositionY: center;
  --pf-c-form-control--success--BackgroundPosition: var(--pf-c-form-control--success--BackgroundPositionX) var(--pf-c-form-control--success--BackgroundPositionY);
  --pf-c-form-control--success--BackgroundSizeX: var(--pf-c-form-control--FontSize);
  --pf-c-form-control--success--BackgroundSizeY: var(--pf-c-form-control--FontSize);
  --pf-c-form-control--success--BackgroundSize: var(--pf-c-form-control--success--BackgroundSizeX) var(--pf-c-form-control--success--BackgroundSizeY);
  --pf-c-form-control--success--BackgroundUrl: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%233e8635' d='M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z'/%3E%3C/svg%3E");
  --pf-c-form-control--m-warning--BorderBottomWidth: var(--pf-global--BorderWidth--md);
  --pf-c-form-control--m-warning--PaddingBottom: calc(var(--pf-global--spacer--form-element) - var(--pf-c-form-control--m-warning--BorderBottomWidth));
  --pf-c-form-control--m-warning--BorderBottomColor: var(--pf-global--warning-color--100);
  --pf-c-form-control--m-warning--PaddingRight: var(--pf-global--spacer--xl);
  --pf-c-form-control--m-warning--BackgroundPositionX: calc(100% - calc(var(--pf-c-form-control--PaddingLeft) - 0.0625rem));
  --pf-c-form-control--m-warning--BackgroundPositionY: center;
  --pf-c-form-control--m-warning--BackgroundPosition: var(--pf-c-form-control--m-warning--BackgroundPositionX) var(--pf-c-form-control--m-warning--BackgroundPositionY);
  --pf-c-form-control--m-warning--BackgroundSizeX: 1.25rem;
  --pf-c-form-control--m-warning--BackgroundSizeY: var(--pf-c-form-control--FontSize);
  --pf-c-form-control--m-warning--BackgroundSize: var(--pf-c-form-control--m-warning--BackgroundSizeX) var(--pf-c-form-control--m-warning--BackgroundSizeY);
  --pf-c-form-control--m-warning--BackgroundUrl: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23f0ab00' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E");
  --pf-c-form-control--invalid--BorderBottomWidth: var(--pf-global--BorderWidth--md);
  --pf-c-form-control--invalid--PaddingBottom: calc(var(--pf-global--spacer--form-element) - var(--pf-c-form-control--invalid--BorderBottomWidth));
  --pf-c-form-control--invalid--BorderBottomColor: var(--pf-global--danger-color--100);
  --pf-c-form-control--invalid--PaddingRight: var(--pf-global--spacer--xl);
  --pf-c-form-control--invalid--BackgroundPositionX: calc(100% - var(--pf-c-form-control--PaddingLeft));
  --pf-c-form-control--invalid--BackgroundPositionY: center;
  --pf-c-form-control--invalid--BackgroundPosition: var(--pf-c-form-control--invalid--BackgroundPositionX) var(--pf-c-form-control--invalid--BackgroundPositionY);
  --pf-c-form-control--invalid--BackgroundSizeX: var(--pf-c-form-control--FontSize);
  --pf-c-form-control--invalid--BackgroundSizeY: var(--pf-c-form-control--FontSize);
  --pf-c-form-control--invalid--BackgroundSize: var(--pf-c-form-control--invalid--BackgroundSizeX) var(--pf-c-form-control--invalid--BackgroundSizeY);
  --pf-c-form-control--invalid--BackgroundUrl: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23c9190b' d='M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E");
  --pf-c-form-control--invalid--exclamation--Background: var(--pf-c-form-control--invalid--BackgroundUrl) var(--pf-c-form-control--invalid--BackgroundPosition) / var(--pf-c-form-control--invalid--BackgroundSize) no-repeat;
  --pf-c-form-control--invalid--Background: var(--pf-c-form-control--BackgroundColor) var(--pf-c-form-control--invalid--exclamation--Background);
  --pf-c-form-control--m-search--PaddingLeft: var(--pf-global--spacer--xl);
  --pf-c-form-control--m-search--BackgroundPosition: var(--pf-c-form-control--PaddingRight);
  --pf-c-form-control--m-search--BackgroundSize: var(--pf-c-form-control--FontSize) var(--pf-c-form-control--FontSize);
  --pf-c-form-control--m-search--BackgroundUrl: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%236a6e73' d='M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z'/%3E%3C/svg%3E");
  --pf-c-form-control--m-icon--PaddingRight: calc(var(--pf-c-form-control--inset--base) + var(--pf-c-form-control--m-icon--BackgroundSizeX) + var(--pf-c-form-control--m-icon--icon--spacer));
  --pf-c-form-control--m-icon--BackgroundUrl: none;
  --pf-c-form-control--m-icon--BackgroundPositionX: calc(100% - var(--pf-c-form-control--inset--base));
  --pf-c-form-control--m-icon--BackgroundPositionY: center;
  --pf-c-form-control--m-icon--BackgroundSizeX: var(--pf-c-form-control--FontSize);
  --pf-c-form-control--m-icon--BackgroundSizeY: var(--pf-c-form-control--FontSize);
  --pf-c-form-control--m-icon--icon--spacer: var(--pf-global--spacer--sm);
  --pf-c-form-control--m-icon--icon--PaddingRight: calc(var(--pf-c-form-control--inset--base) + var(--pf-c-form-control--invalid--BackgroundSizeX) + var(--pf-c-form-control--m-icon--icon--spacer) + var(--pf-c-form-control--m-icon--BackgroundSizeX) + var(--pf-c-form-control--m-icon--icon--spacer));
  --pf-c-form-control--m-icon--icon--BackgroundPositionX: calc(var(--pf-c-form-control--m-icon--BackgroundPositionX) - var(--pf-c-form-control--m-icon--icon--spacer) - var(--pf-c-form-control--invalid--BackgroundSizeX));
  --pf-c-form-control--m-icon--invalid--BackgroundUrl: var(--pf-c-form-control--invalid--BackgroundUrl), var(--pf-c-form-control--m-icon--BackgroundUrl);
  --pf-c-form-control--m-icon--invalid--BackgroundPosition: var(--pf-c-form-control--invalid--BackgroundPosition), var(--pf-c-form-control--m-icon--icon--BackgroundPositionX) var(--pf-c-form-control--m-icon--BackgroundPositionY);
  --pf-c-form-control--m-icon--invalid--BackgroundSize: var(--pf-c-form-control--invalid--BackgroundSize), var(--pf-c-form-control--m-icon--BackgroundSizeX) var(--pf-c-form-control--m-icon--BackgroundSizeY);
  --pf-c-form-control--m-icon--success--BackgroundUrl: var(--pf-c-form-control--success--BackgroundUrl), var(--pf-c-form-control--m-icon--BackgroundUrl);
  --pf-c-form-control--m-icon--success--BackgroundPosition: var(--pf-c-form-control--success--BackgroundPosition), var(--pf-c-form-control--m-icon--icon--BackgroundPositionX) var(--pf-c-form-control--m-icon--BackgroundPositionY);
  --pf-c-form-control--m-icon--success--BackgroundSize: var(--pf-c-form-control--success--BackgroundSize), var(--pf-c-form-control--m-icon--BackgroundSizeX) var(--pf-c-form-control--m-icon--BackgroundSizeY);
  --pf-c-form-control--m-icon--m-warning--BackgroundUrl: var(--pf-c-form-control--m-warning--BackgroundUrl), var(--pf-c-form-control--m-icon--BackgroundUrl);
  --pf-c-form-control--m-icon--m-warning--BackgroundPosition: var(--pf-c-form-control--m-warning--BackgroundPosition), var(--pf-c-form-control--m-icon--icon--BackgroundPositionX) var(--pf-c-form-control--m-icon--BackgroundPositionY);
  --pf-c-form-control--m-icon--m-warning--BackgroundSize: var(--pf-c-form-control--m-warning--BackgroundSize), var(--pf-c-form-control--m-icon--BackgroundSizeX) var(--pf-c-form-control--m-icon--BackgroundSizeY);
  --pf-c-form-control--m-calendar--BackgroundUrl: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%236a6e73' d='M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm320-196c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM192 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM64 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z'/%3E%3C/svg%3E");
  --pf-c-form-control--m-clock--BackgroundUrl: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%236a6e73' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z'/%3E%3C/svg%3E");
  --pf-c-form-control__select--PaddingRight: var(--pf-global--spacer--lg);
  --pf-c-form-control__select--BackgroundUrl: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='%23urrentColor' d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z'/%3E%3C/svg%3E");
  --pf-c-form-control__select--BackgroundSize: .625em;
  --pf-c-form-control__select--BackgroundPositionX: calc(100% - var(--pf-global--spacer--md) + 1px);
  --pf-c-form-control__select--BackgroundPositionY: center;
  --pf-c-form-control__select--BackgroundPosition: var(--pf-c-form-control__select--BackgroundPositionX) var(--pf-c-form-control__select--BackgroundPositionY);
  --pf-c-form-control__select--success--PaddingRight: var(--pf-global--spacer--3xl);
  --pf-c-form-control__select--success--BackgroundPosition: calc(var(--pf-c-form-control__select--BackgroundPositionX) - var(--pf-global--spacer--lg));
  --pf-c-form-control__select--m-warning--PaddingRight: var(--pf-global--spacer--3xl);
  --pf-c-form-control__select--m-warning--BackgroundPosition: calc(var(--pf-c-form-control__select--BackgroundPositionX) - var(--pf-global--spacer--lg) + 0.0625rem);
  --pf-c-form-control__select--invalid--PaddingRight: var(--pf-global--spacer--3xl);
  --pf-c-form-control__select--invalid--BackgroundPosition: calc(var(--pf-c-form-control__select--BackgroundPositionX) - var(--pf-global--spacer--lg));
  --pf-c-form-control--textarea--Width: var(--pf-c-form-control--Width);
  --pf-c-form-control--textarea--Height: auto;
  --pf-c-form-control--textarea--success--BackgroundPositionY: var(--pf-c-form-control--PaddingLeft);
  --pf-c-form-control--textarea--m-warning--BackgroundPositionY: var(--pf-c-form-control--PaddingLeft);
  --pf-c-form-control--textarea--invalid--BackgroundPositionY: var(--pf-c-form-control--PaddingLeft);
  color: var(--pf-global--Color--100);
  width: var(--pf-c-form-control--Width);
  padding: var(--pf-c-form-control--PaddingTop) var(--pf-c-form-control--PaddingRight) var(--pf-c-form-control--PaddingBottom) var(--pf-c-form-control--PaddingLeft);
  font-size: var(--pf-c-form-control--FontSize);
  line-height: var(--pf-c-form-control--LineHeight);
  background-color: var(--pf-c-form-control--BackgroundColor);
  background-repeat: no-repeat;
  border: var(--pf-c-form-control--BorderWidth) solid;
  border-color: var(--pf-c-form-control--BorderTopColor) var(--pf-c-form-control--BorderRightColor) var(--pf-c-form-control--BorderBottomColor) var(--pf-c-form-control--BorderLeftColor);
  border-radius: var(--pf-c-form-control--BorderRadius);
  -moz-appearance: none;
  -webkit-appearance: none;
}
.pf-c-form-control::placeholder {
  color: var(--pf-c-form-control--placeholder--Color);
}
.pf-c-form-control:not(textarea) {
  height: var(--pf-c-form-control--Height);
  text-overflow: ellipsis;
}
.pf-c-form-control[readonly] {
  background-color: var(--pf-c-form-control--readonly--BackgroundColor);
}
.pf-c-form-control[readonly]:not(.pf-m-success):not([aria-invalid=true]):hover {
  --pf-c-form-control--BorderBottomColor: var(--pf-c-form-control--readonly--hover--BorderBottomColor);
}
.pf-c-form-control[readonly]:not(.pf-m-success):not([aria-invalid=true]):focus {
  --pf-c-form-control--focus--PaddingBottom: var(--pf-c-form-control--readonly--focus--PaddingBottom);
  --pf-c-form-control--focus--BorderBottomWidth: var(--pf-c-form-control--readonly--focus--BorderBottomWidth);
  --pf-c-form-control--focus--BorderBottomColor: var(--pf-c-form-control--readonly--focus--BorderBottomColor);
}
.pf-c-form-control:hover {
  --pf-c-form-control--BorderBottomColor: var(--pf-c-form-control--hover--BorderBottomColor);
}
.pf-c-form-control:focus {
  --pf-c-form-control--BorderBottomColor: var(--pf-c-form-control--focus--BorderBottomColor);
  padding-bottom: var(--pf-c-form-control--focus--PaddingBottom);
  border-bottom-width: var(--pf-c-form-control--focus--BorderBottomWidth);
}
.pf-c-form-control.pf-m-expanded {
  --pf-c-form-control--BorderBottomColor: var(--pf-c-form-control--m-expanded--BorderBottomColor);
  padding-bottom: var(--pf-c-form-control--m-expanded--PaddingBottom);
  border-bottom-width: var(--pf-c-form-control--m-expanded--BorderBottomWidth);
}
.pf-c-form-control:disabled {
  --pf-c-form-control--Color: var(--pf-c-form-control--disabled--Color);
  --pf-c-form-control--BackgroundColor: var(--pf-c-form-control--disabled--BackgroundColor);
  cursor: not-allowed;
  border-color: var(--pf-c-form-control--disabled--BorderColor);
}
.pf-c-form-control[aria-invalid=true] {
  --pf-c-form-control--PaddingRight: var(--pf-c-form-control--invalid--PaddingRight);
  --pf-c-form-control--BorderBottomColor: var(--pf-c-form-control--invalid--BorderBottomColor);
  padding-bottom: var(--pf-c-form-control--invalid--PaddingBottom);
  background-image: var(--pf-c-form-control--invalid--BackgroundUrl);
  background-position: var(--pf-c-form-control--invalid--BackgroundPosition);
  background-size: var(--pf-c-form-control--invalid--BackgroundSize);
  border-bottom-width: var(--pf-c-form-control--invalid--BorderBottomWidth);
}
.pf-c-form-control[aria-invalid=true].pf-m-icon {
  --pf-c-form-control--PaddingRight: var(--pf-c-form-control--m-icon--icon--PaddingRight);
  background-image: var(--pf-c-form-control--m-icon--invalid--BackgroundUrl);
  background-position: var(--pf-c-form-control--m-icon--invalid--BackgroundPosition);
  background-size: var(--pf-c-form-control--m-icon--invalid--BackgroundSize);
}
.pf-c-form-control.pf-m-success {
  --pf-c-form-control--PaddingRight: var(--pf-c-form-control--success--PaddingRight);
  --pf-c-form-control--BorderBottomColor: var(--pf-c-form-control--success--BorderBottomColor);
  padding-bottom: var(--pf-c-form-control--success--PaddingBottom);
  background-image: var(--pf-c-form-control--success--BackgroundUrl);
  background-position: var(--pf-c-form-control--success--BackgroundPosition);
  background-size: var(--pf-c-form-control--success--BackgroundSize);
  border-bottom-width: var(--pf-c-form-control--success--BorderBottomWidth);
}
.pf-c-form-control.pf-m-success.pf-m-icon {
  --pf-c-form-control--PaddingRight: var(--pf-c-form-control--m-icon--icon--PaddingRight);
  background-image: var(--pf-c-form-control--m-icon--success--BackgroundUrl);
  background-position: var(--pf-c-form-control--m-icon--success--BackgroundPosition);
  background-size: var(--pf-c-form-control--m-icon--success--BackgroundSize);
}
.pf-c-form-control.pf-m-warning {
  --pf-c-form-control--PaddingRight: var(--pf-c-form-control--m-warning--PaddingRight);
  --pf-c-form-control--BorderBottomColor: var(--pf-c-form-control--m-warning--BorderBottomColor);
  padding-bottom: var(--pf-c-form-control--m-warning--PaddingBottom);
  background-image: var(--pf-c-form-control--m-warning--BackgroundUrl);
  background-position: var(--pf-c-form-control--m-warning--BackgroundPosition);
  background-size: var(--pf-c-form-control--m-warning--BackgroundSize);
  border-bottom-width: var(--pf-c-form-control--m-warning--BorderBottomWidth);
}
.pf-c-form-control.pf-m-warning.pf-m-icon {
  --pf-c-form-control--PaddingRight: var(--pf-c-form-control--m-icon--icon--PaddingRight);
  background-image: var(--pf-c-form-control--m-icon--m-warning--BackgroundUrl);
  background-position: var(--pf-c-form-control--m-icon--m-warning--BackgroundPosition);
  background-size: var(--pf-c-form-control--m-icon--m-warning--BackgroundSize);
}
.pf-c-form-control.pf-m-search {
  --pf-c-form-control--PaddingLeft: var(--pf-c-form-control--m-search--PaddingLeft);
  background-image: var(--pf-c-form-control--m-search--BackgroundUrl);
  background-position: var(--pf-c-form-control--m-search--BackgroundPosition);
  background-size: var(--pf-c-form-control--m-search--BackgroundSize);
}
.pf-c-form-control.pf-m-icon {
  --pf-c-form-control--PaddingRight: var(--pf-c-form-control--m-icon--PaddingRight);
  background-image: var(--pf-c-form-control--m-icon--BackgroundUrl);
  background-position: var(--pf-c-form-control--m-icon--BackgroundPositionX) var(--pf-c-form-control--m-icon--BackgroundPositionY);
  background-size: var(--pf-c-form-control--m-icon--BackgroundSizeX) var(--pf-c-form-control--m-icon--BackgroundSizeY);
}
.pf-c-form-control.pf-m-icon.pf-m-calendar {
  --pf-c-form-control--m-icon--BackgroundUrl: var(--pf-c-form-control--m-calendar--BackgroundUrl);
}
.pf-c-form-control.pf-m-icon.pf-m-clock {
  --pf-c-form-control--m-icon--BackgroundUrl: var(--pf-c-form-control--m-clock--BackgroundUrl);
}
select.pf-c-form-control {
  --pf-c-form-control--PaddingRight: var(--pf-c-form-control__select--PaddingRight);
  background-image: var(--pf-c-form-control__select--BackgroundUrl);
  background-position: var(--pf-c-form-control__select--BackgroundPosition);
  background-size: var(--pf-c-form-control__select--BackgroundSize);
}
select.pf-c-form-control[aria-invalid=true] {
  --pf-c-form-control--PaddingRight: var(--pf-c-form-control__select--invalid--PaddingRight);
  --pf-c-form-control--invalid--BackgroundPosition: var(--pf-c-form-control__select--invalid--BackgroundPosition);
  background-image: var(--pf-c-form-control__select--BackgroundUrl), var(--pf-c-form-control--invalid--BackgroundUrl);
  background-position: var(--pf-c-form-control__select--BackgroundPosition), var(--pf-c-form-control--invalid--BackgroundPosition);
  background-size: var(--pf-c-form-control__select--BackgroundSize), var(--pf-c-form-control--invalid--BackgroundSize);
}
select.pf-c-form-control.pf-m-success {
  --pf-c-form-control--PaddingRight: var(--pf-c-form-control__select--success--PaddingRight);
  --pf-c-form-control--success--BackgroundPosition: var(--pf-c-form-control__select--success--BackgroundPosition);
  background-image: var(--pf-c-form-control__select--BackgroundUrl), var(--pf-c-form-control--success--BackgroundUrl);
  background-position: var(--pf-c-form-control__select--BackgroundPosition), var(--pf-c-form-control--success--BackgroundPosition);
  background-size: var(--pf-c-form-control__select--BackgroundSize), var(--pf-c-form-control--success--BackgroundSize);
}
select.pf-c-form-control.pf-m-warning {
  --pf-c-form-control--PaddingRight: var(--pf-c-form-control__select--m-warning--PaddingRight);
  background-image: var(--pf-c-form-control__select--BackgroundUrl), var(--pf-c-form-control--m-warning--BackgroundUrl);
  background-position: var(--pf-c-form-control__select--BackgroundPosition), var(--pf-c-form-control__select--m-warning--BackgroundPosition);
  background-size: var(--pf-c-form-control__select--BackgroundSize), var(--pf-c-form-control--m-warning--BackgroundSize);
}
select.pf-c-form-control.pf-m-placeholder {
  color: var(--pf-c-form-control--placeholder--Color);
}

textarea.pf-c-form-control {
  --pf-c-form-control--success--BackgroundPositionY: var(--pf-c-form-control--textarea--success--BackgroundPositionY);
  --pf-c-form-control--invalid--BackgroundPositionY: var(--pf-c-form-control--textarea--invalid--BackgroundPositionY);
  --pf-c-form-control--m-warning--BackgroundPositionY: var(--pf-c-form-control--textarea--m-warning--BackgroundPositionY);
  width: var(--pf-c-form-control--textarea--Width);
  height: var(--pf-c-form-control--textarea--Height);
}

.pf-c-form-control.pf-m-resize-vertical {
  resize: vertical;
}
.pf-c-form-control.pf-m-resize-horizontal {
  resize: horizontal;
}