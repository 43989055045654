.pf-c-banner.pf-m-warning, .pf-c-banner.pf-m-info {
  --pf-global--Color--100: var(--pf-global--Color--dark-100);
  --pf-global--Color--200: var(--pf-global--Color--dark-200);
  --pf-global--BorderColor--100: var(--pf-global--BorderColor--dark-100);
  --pf-global--primary-color--100: var(--pf-global--primary-color--dark-100);
  --pf-global--link--Color: var(--pf-global--link--Color--dark);
  --pf-global--link--Color--hover: var(--pf-global--link--Color--dark--hover);
  --pf-global--BackgroundColor--100: var(--pf-global--BackgroundColor--light-100);
}

.pf-c-banner {
  --pf-global--Color--100: var(--pf-global--Color--light-100);
  --pf-global--Color--200: var(--pf-global--Color--light-200);
  --pf-global--BorderColor--100: var(--pf-global--BorderColor--light-100);
  --pf-global--primary-color--100: var(--pf-global--primary-color--light-100);
  --pf-global--link--Color: var(--pf-global--link--Color--light);
  --pf-global--link--Color--hover: var(--pf-global--link--Color--light);
  --pf-global--BackgroundColor--100: var(--pf-global--BackgroundColor--dark-100);
}
.pf-c-banner .pf-c-card {
  --pf-c-card--BackgroundColor: var(--pf-global--BackgroundColor--dark-transparent-200);
}
.pf-c-banner .pf-c-button {
  --pf-c-button--m-primary--Color: var(--pf-global--primary-color--dark-100);
  --pf-c-button--m-primary--hover--Color: var(--pf-global--primary-color--dark-100);
  --pf-c-button--m-primary--focus--Color: var(--pf-global--primary-color--dark-100);
  --pf-c-button--m-primary--active--Color: var(--pf-global--primary-color--dark-100);
  --pf-c-button--m-primary--BackgroundColor: var(--pf-global--BackgroundColor--light-100);
  --pf-c-button--m-primary--hover--BackgroundColor: var(--pf-global--BackgroundColor--light-300);
  --pf-c-button--m-primary--focus--BackgroundColor: var(--pf-global--BackgroundColor--light-300);
  --pf-c-button--m-primary--active--BackgroundColor: var(--pf-global--BackgroundColor--light-300);
  --pf-c-button--m-secondary--Color: var(--pf-global--Color--light-100);
  --pf-c-button--m-secondary--hover--Color: var(--pf-global--Color--light-100);
  --pf-c-button--m-secondary--focus--Color: var(--pf-global--Color--light-100);
  --pf-c-button--m-secondary--active--Color: var(--pf-global--Color--light-100);
  --pf-c-button--m-secondary--BorderColor: var(--pf-global--Color--light-100);
  --pf-c-button--m-secondary--hover--BorderColor: var(--pf-global--Color--light-100);
  --pf-c-button--m-secondary--focus--BorderColor: var(--pf-global--Color--light-100);
  --pf-c-button--m-secondary--active--BorderColor: var(--pf-global--Color--light-100);
}

.pf-c-banner {
  --pf-c-banner--PaddingTop: var(--pf-global--spacer--xs);
  --pf-c-banner--PaddingRight: var(--pf-global--spacer--md);
  --pf-c-banner--md--PaddingRight: var(--pf-global--spacer--lg);
  --pf-c-banner--PaddingBottom: var(--pf-global--spacer--xs);
  --pf-c-banner--PaddingLeft: var(--pf-global--spacer--md);
  --pf-c-banner--md--PaddingLeft: var(--pf-global--spacer--lg);
  --pf-c-banner--FontSize: var(--pf-global--FontSize--sm);
  --pf-c-banner--Color: var(--pf-global--Color--100);
  --pf-c-banner--BackgroundColor: var(--pf-global--BackgroundColor--dark-400);
  --pf-c-banner--m-info--BackgroundColor: var(--pf-global--palette--blue-200);
  --pf-c-banner--m-danger--BackgroundColor: var(--pf-global--danger-color--100);
  --pf-c-banner--m-success--BackgroundColor: var(--pf-global--success-color--100);
  --pf-c-banner--m-warning--BackgroundColor: var(--pf-global--warning-color--100);
  --pf-c-banner--m-sticky--ZIndex: var(--pf-global--ZIndex--md);
  --pf-c-banner--m-sticky--BoxShadow: var(--pf-global--BoxShadow--md-bottom);
  color: var(--pf-global--Color--100);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: var(--pf-c-banner--PaddingTop) var(--pf-c-banner--PaddingRight) var(--pf-c-banner--PaddingBottom) var(--pf-c-banner--PaddingLeft);
  flex-shrink: 0;
  font-size: var(--pf-c-banner--FontSize);
  color: var(--pf-c-banner--Color);
  white-space: nowrap;
  background-color: var(--pf-c-banner--BackgroundColor);
}
@media (min-width: 768px) {
  .pf-c-banner {
    --pf-c-banner--PaddingRight: var(--pf-c-banner--md--PaddingRight);
    --pf-c-banner--PaddingLeft: var(--pf-c-banner--md--PaddingLeft);
  }
}
.pf-c-banner.pf-m-info {
  color: var(--pf-global--Color--100);
  --pf-c-banner--BackgroundColor: var(--pf-c-banner--m-info--BackgroundColor);
}
.pf-c-banner.pf-m-danger {
  --pf-c-banner--BackgroundColor: var(--pf-c-banner--m-danger--BackgroundColor);
}
.pf-c-banner.pf-m-success {
  --pf-c-banner--BackgroundColor: var(--pf-c-banner--m-success--BackgroundColor);
}
.pf-c-banner.pf-m-warning {
  color: var(--pf-global--Color--100);
  --pf-c-banner--BackgroundColor: var(--pf-c-banner--m-warning--BackgroundColor);
}
.pf-c-banner.pf-m-sticky {
  position: sticky;
  top: 0;
  z-index: var(--pf-c-banner--m-sticky--ZIndex);
  box-shadow: var(--pf-c-banner--m-sticky--BoxShadow);
}