.pf-c-check {
  --pf-c-check--GridGap: var(--pf-global--spacer--xs) var(--pf-global--spacer--sm);
  --pf-c-check__label--disabled--Color: var(--pf-global--disabled-color--100);
  --pf-c-check__label--Color: var(--pf-global--Color--100);
  --pf-c-check__label--FontWeight: var(--pf-global--FontWeight--normal);
  --pf-c-check__label--FontSize: var(--pf-global--FontSize--md);
  --pf-c-check__label--LineHeight: var(--pf-global--LineHeight--sm);
  --pf-c-check__input--MarginTop: -0.1875rem;
  --pf-c-check__input--Height: calc(var(--pf-c-check__label--FontSize) * var(--pf-c-check__label--LineHeight));
  --pf-c-check__input--MarginTop: calc(-1px * (var(--pf-c-check__label--LineHeight) * 1.25));
  --pf-c-check__description--FontSize: var(--pf-global--FontSize--sm);
  --pf-c-check__description--Color: var(--pf-global--Color--200);
  --pf-c-check__body--MarginTop: var(--pf-global--spacer--sm);
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: var(--pf-c-check--GridGap);
  align-items: start;
  justify-items: start;
}
.pf-c-check.pf-m-standalone {
  --pf-c-check--GridGap: 0;
  --pf-c-check__input--Height: auto;
  --pf-c-check__input--MarginTop: 0;
  display: inline-grid;
  line-height: 1;
}

.pf-c-check__label {
  font-size: var(--pf-c-check__label--FontSize);
  font-weight: var(--pf-c-check__label--FontWeight);
  line-height: var(--pf-c-check__label--LineHeight);
  color: var(--pf-c-check__label--Color);
}

.pf-c-check__input {
  height: var(--pf-c-check__input--Height);
  margin-top: var(--pf-c-check__input--MarginTop);
}

.pf-c-check__description {
  grid-column: 2;
  font-size: var(--pf-c-check__description--FontSize);
  color: var(--pf-c-check__description--Color);
}

.pf-c-check__body {
  grid-column: 2;
  margin-top: var(--pf-c-check__body--MarginTop);
}

label.pf-c-check, .pf-c-check__label,
.pf-c-check__input {
  cursor: pointer;
}

.pf-c-check__label:disabled, .pf-c-check__label.pf-m-disabled,
.pf-c-check__input:disabled,
.pf-c-check__input.pf-m-disabled {
  --pf-c-check__label--Color: var(--pf-c-check__label--disabled--Color);
  cursor: not-allowed;
}