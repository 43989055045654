.pf-c-accordion {
  --pf-global--Color--100: var(--pf-global--Color--dark-100);
  --pf-global--Color--200: var(--pf-global--Color--dark-200);
  --pf-global--BorderColor--100: var(--pf-global--BorderColor--dark-100);
  --pf-global--primary-color--100: var(--pf-global--primary-color--dark-100);
  --pf-global--link--Color: var(--pf-global--link--Color--dark);
  --pf-global--link--Color--hover: var(--pf-global--link--Color--dark--hover);
  --pf-global--BackgroundColor--100: var(--pf-global--BackgroundColor--light-100);
}

.pf-c-accordion {
  --pf-c-accordion--BackgroundColor: var(--pf-global--BackgroundColor--100);
  --pf-c-accordion__toggle--PaddingTop: var(--pf-global--spacer--sm);
  --pf-c-accordion__toggle--PaddingRight: var(--pf-global--spacer--md);
  --pf-c-accordion__toggle--PaddingBottom: var(--pf-global--spacer--sm);
  --pf-c-accordion__toggle--PaddingLeft: var(--pf-global--spacer--md);
  --pf-c-accordion__toggle--before--BackgroundColor: transparent;
  --pf-c-accordion__toggle--before--Top: 0;
  --pf-c-accordion__toggle--hover--BackgroundColor: var(--pf-global--BackgroundColor--200);
  --pf-c-accordion__toggle--focus--BackgroundColor: var(--pf-global--BackgroundColor--200);
  --pf-c-accordion__toggle--active--BackgroundColor: var(--pf-global--BackgroundColor--200);
  --pf-c-accordion__toggle--before--Width: var(--pf-global--BorderWidth--lg);
  --pf-c-accordion__toggle--m-expanded--before--BackgroundColor: var(--pf-global--primary-color--100);
  --pf-c-accordion__toggle-text--MaxWidth: calc(100% - var(--pf-global--spacer--lg));
  --pf-c-accordion__toggle--hover__toggle-text--Color: var(--pf-global--link--Color);
  --pf-c-accordion__toggle--active__toggle-text--Color: var(--pf-global--link--Color);
  --pf-c-accordion__toggle--active__toggle-text--FontWeight: var(--pf-global--FontWeight--semi-bold);
  --pf-c-accordion__toggle--focus__toggle-text--Color: var(--pf-global--link--Color);
  --pf-c-accordion__toggle--focus__toggle-text--FontWeight: var(--pf-global--FontWeight--semi-bold);
  --pf-c-accordion__toggle--m-expanded__toggle-text--Color: var(--pf-global--link--Color);
  --pf-c-accordion__toggle--m-expanded__toggle-text--FontWeight: var(--pf-global--FontWeight--semi-bold);
  --pf-c-accordion__toggle-icon--Transition: .2s ease-in 0s;
  --pf-c-accordion__toggle--m-expanded__toggle-icon--Rotate: 90deg;
  --pf-c-accordion__expanded-content--Color: var(--pf-global--Color--200);
  --pf-c-accordion__expanded-content--FontSize: var(--pf-global--FontSize--sm);
  --pf-c-accordion__expanded-content--m-expanded__expanded-content-body--before--BackgroundColor: var(--pf-global--primary-color--100);
  --pf-c-accordion__expanded-content--m-fixed--MaxHeight: 9.375rem;
  --pf-c-accordion__expanded-content-body--PaddingTop: var(--pf-global--spacer--sm);
  --pf-c-accordion__expanded-content-body--PaddingRight: var(--pf-global--spacer--md);
  --pf-c-accordion__expanded-content-body--PaddingBottom: var(--pf-global--spacer--sm);
  --pf-c-accordion__expanded-content-body--PaddingLeft: var(--pf-global--spacer--md);
  --pf-c-accordion__expanded-content-body--expanded-content-body--PaddingTop: 0;
  --pf-c-accordion__expanded-content-body--before--BackgroundColor: transparent;
  --pf-c-accordion__expanded-content-body--before--Width: var(--pf-global--BorderWidth--lg);
  --pf-c-accordion__expanded-content-body--before--Top: 0;
  --pf-c-accordion--m-display-lg__toggle--PaddingTop: var(--pf-global--spacer--md);
  --pf-c-accordion--m-display-lg__toggle--PaddingRight: var(--pf-global--spacer--md);
  --pf-c-accordion--m-display-lg__toggle--PaddingBottom: var(--pf-global--spacer--md);
  --pf-c-accordion--m-display-lg__toggle--PaddingLeft: var(--pf-global--spacer--lg);
  --pf-c-accordion--m-display-lg__toggle--FontFamily: var(--pf-global--FontFamily--heading--sans-serif);
  --pf-c-accordion--m-display-lg__toggle--FontSize: var(--pf-global--FontSize--xl);
  --pf-c-accordion--m-display-lg__toggle--hover__toggle-text--Color: var(--pf-global--Color--100);
  --pf-c-accordion--m-display-lg__toggle--active__toggle-text--Color: var(--pf-global--Color--100);
  --pf-c-accordion--m-display-lg__toggle--active__toggle-text--FontWeight: var(--pf-global--FontWeight--normal);
  --pf-c-accordion--m-display-lg__toggle--focus__toggle-text--Color: var(--pf-global--Color--100);
  --pf-c-accordion--m-display-lg__toggle--focus__toggle-text--FontWeight: var(--pf-global--FontWeight--normal);
  --pf-c-accordion--m-display-lg__toggle--m-expanded__toggle-text--Color: var(--pf-global--Color--100);
  --pf-c-accordion--m-display-lg__toggle--m-expanded__toggle-text--FontWeight: var(--pf-global--FontWeight--normal);
  --pf-c-accordion--m-display-lg__expanded-content--FontSize: var(--pf-global--FontSize--md);
  --pf-c-accordion--m-display-lg__expanded-content--Color: var(--pf-global--Color--100);
  --pf-c-accordion--m-display-lg__expanded-content-body--PaddingTop: 0;
  --pf-c-accordion--m-display-lg__expanded-content-body--PaddingRight: var(--pf-global--spacer--md);
  --pf-c-accordion--m-display-lg__expanded-content-body--PaddingBottom: var(--pf-global--spacer--md);
  --pf-c-accordion--m-display-lg__expanded-content-body--last-child--PaddingBottom: var(--pf-global--spacer--lg);
  --pf-c-accordion--m-display-lg__expanded-content-body--PaddingLeft: var(--pf-global--spacer--lg);
  --pf-c-accordion--m-bordered--BorderTopWidth: var(--pf-global--BorderWidth--sm);
  --pf-c-accordion--m-bordered--BorderTopColor: var(--pf-global--BorderColor--100);
  --pf-c-accordion--m-bordered__toggle--before--Top: calc(-1 * var(--pf-global--BorderWidth--sm));
  --pf-c-accordion--m-bordered__toggle--after--BorderColor: var(--pf-global--BorderColor--100);
  --pf-c-accordion--m-bordered__toggle--after--BorderTopWidth: 0;
  --pf-c-accordion--m-bordered__toggle--after--BorderBottomWidth: var(--pf-global--BorderWidth--sm);
  --pf-c-accordion--m-bordered__expanded-content--m-expanded__expanded-content-body--last-child--after--BorderBottomWidth: var(--pf-global--BorderWidth--sm);
  --pf-c-accordion--m-bordered__expanded-content--m-expanded__expanded-content-body--last-child--after--BorderBottomColor: var(--pf-global--BorderColor--100);
  color: var(--pf-global--Color--100);
  background-color: var(--pf-c-accordion--BackgroundColor);
}
.pf-c-accordion.pf-m-display-lg {
  --pf-c-accordion__toggle--PaddingTop: var(--pf-c-accordion--m-display-lg__toggle--PaddingTop);
  --pf-c-accordion__toggle--PaddingRight: var(--pf-c-accordion--m-display-lg__toggle--PaddingRight);
  --pf-c-accordion__toggle--PaddingBottom: var(--pf-c-accordion--m-display-lg__toggle--PaddingBottom);
  --pf-c-accordion__toggle--PaddingLeft: var(--pf-c-accordion--m-display-lg__toggle--PaddingLeft);
  --pf-c-accordion__toggle--FontFamily: var(--pf-c-accordion--m-display-lg__toggle--FontFamily);
  --pf-c-accordion__toggle--FontSize: var(--pf-c-accordion--m-display-lg__toggle--FontSize);
  --pf-c-accordion__toggle--hover__toggle-text--Color: var(--pf-c-accordion--m-display-lg__toggle--hover__toggle-text--Color);
  --pf-c-accordion__toggle--active__toggle-text--Color: var(--pf-c-accordion--m-display-lg__toggle--active__toggle-text--Color);
  --pf-c-accordion__toggle--active__toggle-text--FontWeight: var(--pf-c-accordion--m-display-lg__toggle--active__toggle-text--FontWeight);
  --pf-c-accordion__toggle--focus__toggle-text--Color: var(--pf-c-accordion--m-display-lg__toggle--focus__toggle-text--Color);
  --pf-c-accordion__toggle--focus__toggle-text--FontWeight: var(--pf-c-accordion--m-display-lg__toggle--focus__toggle-text--FontWeight);
  --pf-c-accordion__toggle--m-expanded__toggle-text--Color: var(--pf-c-accordion--m-display-lg__toggle--m-expanded__toggle-text--Color);
  --pf-c-accordion__toggle--m-expanded__toggle-text--FontWeight: var(--pf-c-accordion--m-display-lg__toggle--m-expanded__toggle-text--FontWeight);
  --pf-c-accordion__expanded-content-body--PaddingTop: var(--pf-c-accordion--m-display-lg__expanded-content-body--PaddingTop);
  --pf-c-accordion__expanded-content-body--PaddingRight: var(--pf-c-accordion--m-display-lg__expanded-content-body--PaddingRight);
  --pf-c-accordion__expanded-content-body--PaddingBottom: var(--pf-c-accordion--m-display-lg__expanded-content-body--PaddingBottom);
  --pf-c-accordion__expanded-content-body--PaddingLeft: var(--pf-c-accordion--m-display-lg__expanded-content-body--PaddingLeft);
  --pf-c-accordion__expanded-content--FontSize: var(--pf-c-accordion--m-display-lg__expanded-content--FontSize);
  --pf-c-accordion__expanded-content--Color: var(--pf-c-accordion--m-display-lg__expanded-content--Color);
}
.pf-c-accordion.pf-m-display-lg .pf-c-accordion__expanded-content-body:last-child {
  --pf-c-accordion__expanded-content-body--PaddingBottom: var(--pf-c-accordion--m-display-lg__expanded-content-body--last-child--PaddingBottom);
}
.pf-c-accordion.pf-m-bordered {
  --pf-c-accordion__toggle--before--Top: var(--pf-c-accordion--m-bordered__toggle--before--Top);
  border-top: var(--pf-c-accordion--m-bordered--BorderTopWidth) solid var(--pf-c-accordion--m-bordered--BorderTopColor);
}
.pf-c-accordion.pf-m-bordered .pf-c-accordion__toggle::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  border: solid var(--pf-c-accordion--m-bordered__toggle--after--BorderColor);
  border-width: var(--pf-c-accordion--m-bordered__toggle--after--BorderTopWidth) 0 var(--pf-c-accordion--m-bordered__toggle--after--BorderBottomWidth);
}
.pf-c-accordion.pf-m-bordered .pf-c-accordion__toggle.pf-m-expanded {
  --pf-c-accordion--m-bordered__toggle--after--BorderBottomWidth: 0;
}
.pf-c-accordion.pf-m-bordered .pf-c-accordion__expanded-content.pf-m-expanded .pf-c-accordion__expanded-content-body:last-child::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  border-bottom: var(--pf-c-accordion--m-bordered__expanded-content--m-expanded__expanded-content-body--last-child--after--BorderBottomWidth) solid var(--pf-c-accordion--m-bordered__expanded-content--m-expanded__expanded-content-body--last-child--after--BorderBottomColor);
}

.pf-c-accordion__toggle {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: var(--pf-c-accordion__toggle--PaddingTop) var(--pf-c-accordion__toggle--PaddingRight) var(--pf-c-accordion__toggle--PaddingBottom) var(--pf-c-accordion__toggle--PaddingLeft);
  font-family: var(--pf-c-accordion__toggle--FontFamily, inherit);
  font-size: var(--pf-c-accordion__toggle--FontSize, inherit);
  border: 0;
}
.pf-c-accordion__toggle::after {
  position: absolute;
  top: var(--pf-c-accordion__toggle--before--Top);
  bottom: 0;
  left: 0;
  width: var(--pf-c-accordion__toggle--before--Width);
  content: "";
  background-color: var(--pf-c-accordion__toggle--before--BackgroundColor);
}
.pf-c-accordion__toggle.pf-m-expanded {
  --pf-c-accordion__toggle--before--BackgroundColor: var(--pf-c-accordion__toggle--m-expanded--before--BackgroundColor);
}
.pf-c-accordion__toggle.pf-m-expanded .pf-c-accordion__toggle-text {
  font-weight: var(--pf-c-accordion__toggle--m-expanded__toggle-text--FontWeight);
  color: var(--pf-c-accordion__toggle--m-expanded__toggle-text--Color);
}
.pf-c-accordion__toggle.pf-m-expanded .pf-c-accordion__toggle-icon {
  transform: rotate(var(--pf-c-accordion__toggle--m-expanded__toggle-icon--Rotate));
}
.pf-c-accordion__toggle:hover {
  background-color: var(--pf-c-accordion__toggle--hover--BackgroundColor);
}
.pf-c-accordion__toggle:hover .pf-c-accordion__toggle-text {
  color: var(--pf-c-accordion__toggle--hover__toggle-text--Color);
}
.pf-c-accordion__toggle:focus {
  background-color: var(--pf-c-accordion__toggle--focus--BackgroundColor);
}
.pf-c-accordion__toggle:focus .pf-c-accordion__toggle-text {
  font-weight: var(--pf-c-accordion__toggle--focus__toggle-text--FontWeight);
  color: var(--pf-c-accordion__toggle--focus__toggle-text--Color);
}
.pf-c-accordion__toggle:active {
  background-color: var(--pf-c-accordion__toggle--active--BackgroundColor);
}
.pf-c-accordion__toggle:active .pf-c-accordion__toggle-text {
  font-weight: var(--pf-c-accordion__toggle--active__toggle-text--FontWeight);
  color: var(--pf-c-accordion__toggle--active__toggle-text--Color);
}

.pf-c-accordion__toggle-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: var(--pf-c-accordion__toggle-text--MaxWidth);
}

.pf-c-accordion__toggle-icon {
  transition: var(--pf-c-accordion__toggle-icon--Transition);
}

.pf-c-accordion__expanded-content {
  font-size: var(--pf-c-accordion__expanded-content--FontSize);
  color: var(--pf-c-accordion__expanded-content--Color);
}
.pf-c-accordion__expanded-content.pf-m-fixed {
  max-height: var(--pf-c-accordion__expanded-content--m-fixed--MaxHeight);
  overflow-y: auto;
}
.pf-c-accordion__expanded-content.pf-m-expanded {
  --pf-c-accordion__expanded-content-body--before--BackgroundColor: var(--pf-c-accordion__expanded-content--m-expanded__expanded-content-body--before--BackgroundColor);
}

.pf-c-accordion__expanded-content-body {
  position: relative;
  padding: var(--pf-c-accordion__expanded-content-body--PaddingTop) var(--pf-c-accordion__expanded-content-body--PaddingRight) var(--pf-c-accordion__expanded-content-body--PaddingBottom) var(--pf-c-accordion__expanded-content-body--PaddingLeft);
}
.pf-c-accordion__expanded-content-body::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: var(--pf-c-accordion__expanded-content-body--before--Width);
  content: "";
  background-color: var(--pf-c-accordion__expanded-content-body--before--BackgroundColor);
}
.pf-c-accordion__expanded-content-body + .pf-c-accordion__expanded-content-body {
  --pf-c-accordion__expanded-content-body--PaddingTop: var(--pf-c-accordion__expanded-content-body--expanded-content-body--PaddingTop);
}